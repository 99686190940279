import {
  AccountCreate,
  AccountResponse,
  ActivityUnit,
  AppAvailableIngredientsListParams,
  AppAvailablePackagingListParams,
  AppAvailableProcessingListParams,
  AppAvailableTransportListParams,
  AppOrganizationFoodsListParams,
  AppProductsListParams,
  AvailableIngredients,
  AvailablePackaging,
  AvailableProcessing,
  AvailableTransport,
  Batch,
  BatchesListParams,
  CollectionDetail,
  CollectionList,
  CompanyReportDetail,
  CompanyReportsActivitiesFoodListParams,
  Country,
  FoodActivity,
  FoodActivityUpdate,
  IngredientInsightsList,
  PaginatedCompanyReportList,
  PaginatedFoodActivitiesList,
  PaginatedOrganizationFoodListList,
  PaginatedProductListList,
  ProcessingTypeList,
  ProductResult,
  ProductWithRelations,
  ReportOrganizationFoodDetail,
  ReportProductDetail,
  TokenRefresh,
} from '@shared/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useAxiosConfig } from './AxiosConfigProvider';
import {
  getAppAvailableIngredientsListQueryKey,
  getAppAvailablePackagingListQueryKey,
  getAppAvailableProcessingListQueryKey,
  getAppAvailableTransportListQueryKey,
  getAppCollectionsListQueryKey,
  getAppCollectionsRetrieveQueryKey,
  getAppProductsListQueryKey,
  getAppProductsResultRetrieveQueryKey,
  useAppAccountCompleteSignupCreate,
  useAppAccountCreate,
  useAppAccountResendEmailVerificationCreate,
  useAppAccountRetrieve,
  useAppAccountVerifyEmailCreate,
  useAppAttributesCountriesList,
  useAppAvailableIngredientsList,
  useAppAvailablePackagingList,
  useAppAvailableProcessingList,
  useAppAvailableTransportList,
  useAppCollectionsCreate,
  useAppCollectionsDestroy,
  useAppCollectionsList,
  useAppCollectionsRetrieve,
  useAppCollectionsUpdate,
  useAppFoodsInsightsList,
  useAppHomeRetrieve,
  useAppLoginCreate,
  useAppLogoutCreate,
  useAppOrganizationFoodsList,
  useAppPackagingCreate,
  useAppPackagingFileCreate,
  useAppPackagingRetrieve,
  useAppPackagingRetrieve2,
  useAppProcessingCreate,
  useAppProcessingFileCreate,
  useAppProcessingList,
  useAppProductsAssessCreate,
  useAppProductsCsvRetrieve,
  useAppProductsDestroy,
  useAppProductsEditCreate,
  useAppProductsIngredientsList,
  useAppProductsList,
  useAppProductsListInfinite,
  useAppProductsPackagingList,
  useAppProductsResultRetrieve,
  useAppProductsSubmitCreate,
  useAppReportsList,
  useAppReportsProductionRetrieve,
  useAppReportsPurchasingRetrieve,
  useAppReportsSalesRetrieve,
  useAppTokenRefresh2Create,
  useAppTransportCreate,
  useAppTransportFileCreate,
  useAppTransportList,
  useAppUnitsList,
} from './lib/app/app';
import { useBatchesList } from './lib/batches/batches';
import {
  getCompanyReportsActivitiesFoodListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesFoodList,
  useCompanyReportsActivitiesFoodRetrieve,
  useCompanyReportsActivitiesFoodUpdate,
  useCompanyReportsActivityTypesUnitsList,
  useCompanyReportsCreate,
  useCompanyReportsImportsFoodCreate,
  useCompanyReportsImportsRetrieve,
  useCompanyReportsList,
  useCompanyReportsRetrieve,
  useCompanyReportsTimePeriodsList,
} from './lib/company-reports/company-reports';
import {
  getProductsRetrieveQueryKey,
  useProductsAssessCreate,
  useProductsCreate,
  useProductsPartialUpdate,
  useProductsRetrieve,
} from './lib/products/products';

export const useLogin = () => useAppLoginCreate({ request: useAxiosConfig() });

export const useLogout = () =>
  useAppLogoutCreate({ request: useAxiosConfig() });

export const useRefreshToken = () =>
  useAppTokenRefresh2Create<Promise<TokenRefresh>>({
    request: useAxiosConfig(),
  });

export const useVerifyEmail = () =>
  useAppAccountVerifyEmailCreate<Promise<void>>({
    request: useAxiosConfig(),
  });

export const useSendEmailVerification = () =>
  useAppAccountResendEmailVerificationCreate<Promise<void>>({
    request: useAxiosConfig(),
  });

export const useAccount = (enabled: boolean) => {
  return useAppAccountRetrieve<AccountResponse>({
    request: useAxiosConfig(),
    query: {
      enabled,
    },
  });
};

export const useAccountCreate = () =>
  useAppAccountCreate<AccountCreate>({
    request: useAxiosConfig(),
  });

export const useGetBatches = (params?: BatchesListParams) =>
  useBatchesList<Batch[]>(params, { request: useAxiosConfig() });

export const useGetUnitList = () =>
  useAppUnitsList({
    request: useAxiosConfig(),
    query: {
      staleTime: Infinity,
    },
  });

export const useAssessDemoProduct = () =>
  useAppProductsAssessCreate({
    request: useAxiosConfig(),
  });

export const useGetDashboard = () =>
  useAppHomeRetrieve({
    request: useAxiosConfig(),
  });

export const useGetProductsList = (params?: AppProductsListParams) =>
  useAppProductsList<PaginatedProductListList>(params, {
    request: useAxiosConfig(),
    query: {
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  });

export const useGetInfiniteProductsList = (params?: AppProductsListParams) => {
  return useAppProductsListInfinite<PaginatedProductListList>(params, {
    request: useAxiosConfig(),
    query: {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next ? pages.length + 1 : undefined;
      },
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  return useAppProductsDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppProductsListQueryKey());
      },
    },
  });
};

export const useEditProduct = () => {
  const queryClient = useQueryClient();
  return useAppProductsEditCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries([getAppProductsListQueryKey()]);
      },
    },
  });
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();
  return useProductsCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          getAppAvailableIngredientsListQueryKey(),
          getAppProductsListQueryKey(),
          getProductsRetrieveQueryKey(data.uuid),
        ]);
      },
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  return useProductsPartialUpdate<ProductResult>({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData(getProductsRetrieveQueryKey(data.uuid), data);
        queryClient.invalidateQueries(
          getAppProductsResultRetrieveQueryKey(data.uuid)
        );
        queryClient.invalidateQueries([getAppProductsListQueryKey()]);
      },
    },
  });
};

export const useGetProduct = (productUuid: string) => {
  return useProductsRetrieve<ProductWithRelations>(productUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!productUuid,
    },
  });
};
export const useAssessProduct = () => {
  const queryClient = useQueryClient();
  return useProductsAssessCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppProductsListQueryKey());
      },
    },
  });
};

export const useGetProductResult = (productUuid: string) =>
  useAppProductsResultRetrieve<ProductResult>(productUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!productUuid,
    },
  });

export const useGetProductIngredients = (productUuid: string) =>
  useAppProductsIngredientsList(productUuid, {
    request: useAxiosConfig(),
  });

export const useGetProductPackaging = (productUuid: string) =>
  useAppProductsPackagingList(productUuid, {
    request: useAxiosConfig(),
  });

export const useGetReports = () => {
  return useAppReportsList({
    request: useAxiosConfig(),
  });
};

export const useGetPurchasingReport = (reportUuid: string) => {
  return useAppReportsPurchasingRetrieve<ReportOrganizationFoodDetail>(
    reportUuid,
    {
      request: useAxiosConfig(),
    }
  );
};

export const useGetSalesReport = (reportUuid: string) => {
  return useAppReportsSalesRetrieve<ReportProductDetail>(reportUuid, {
    request: useAxiosConfig(),
  });
};

export const useGetProductionReport = (reportUuid: string) => {
  return useAppReportsProductionRetrieve<ReportProductDetail>(reportUuid, {
    request: useAxiosConfig(),
  });
};

export const useGetCollections = () => {
  return useAppCollectionsList<CollectionList[]>({
    request: useAxiosConfig(),
  });
};

export const useGetCollection = (collectionUuid: string) =>
  useAppCollectionsRetrieve<CollectionDetail>(collectionUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!collectionUuid,
    },
  });

export const useCreateCollection = () => {
  const queryClient = useQueryClient();
  return useAppCollectionsCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppCollectionsListQueryKey());
      },
    },
  });
};

export const useEditCollection = () => {
  const queryClient = useQueryClient();
  return useAppCollectionsUpdate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          getAppCollectionsListQueryKey(),
          getAppCollectionsRetrieveQueryKey(data.uuid),
        ]);

        queryClient.removeQueries(getAppCollectionsRetrieveQueryKey(data.uuid));
      },
    },
  });
};

export const useDeleteCollection = () => {
  const queryClient = useQueryClient();
  return useAppCollectionsDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppCollectionsListQueryKey());
      },
    },
  });
};

export const useGetAvailableIngredients = (
  params?: AppAvailableIngredientsListParams
) =>
  useAppAvailableIngredientsList<AvailableIngredients>(params, {
    request: useAxiosConfig(),
    query: {
      staleTime: 1000 * 60 * 60 * 24,
    },
  });

export const useGetAvailablePackaging = (
  params?: AppAvailablePackagingListParams
) =>
  useAppAvailablePackagingList<AvailablePackaging>(params, {
    request: useAxiosConfig(),
    query: {
      staleTime: 1000 * 60 * 60 * 24,
    },
  });

export const useGetAvailableTransport = (
  params?: AppAvailableTransportListParams
) => {
  return useAppAvailableTransportList<AvailableTransport>(params, {
    request: useAxiosConfig(),
  });
};

export const useGetAvailableProcessing = (
  params?: AppAvailableProcessingListParams
) => {
  return useAppAvailableProcessingList<AvailableProcessing>(params, {
    request: useAxiosConfig(),
  });
};

export const useGetIngredients = (params: AppOrganizationFoodsListParams) => {
  return useAppOrganizationFoodsList<PaginatedOrganizationFoodListList[]>(
    params,
    {
      request: useAxiosConfig(),
    }
  );
};

export const useGetIngredientsWithInsights = () => {
  return useAppFoodsInsightsList<IngredientInsightsList[]>({
    request: useAxiosConfig(),
  });
};

export const useSubmitProductAssessment = () => {
  return useAppProductsSubmitCreate({
    request: useAxiosConfig(),
  });
};
export const useCreatePackaging = () => {
  return useAppPackagingCreate({
    request: useAxiosConfig(),
  });
};

export const useCreateProcessing = () => {
  return useAppProcessingCreate({
    request: useAxiosConfig(),
  });
};

export const useCreateTransport = () => {
  return useAppTransportCreate({
    request: useAxiosConfig(),
  });
};

export const usePackagingFileCreate = () => {
  const queryClient = useQueryClient();
  return useAppPackagingFileCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppAvailablePackagingListQueryKey());
      },
    },
  });
};

export const useProcessingFileCreate = () => {
  const queryClient = useQueryClient();
  return useAppProcessingFileCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppAvailableProcessingListQueryKey());
      },
    },
  });
};

export const useTransportFileCreate = () => {
  const queryClient = useQueryClient();
  return useAppTransportFileCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppAvailableTransportListQueryKey());
      },
    },
  });
};
export const useGetProductsCSVExport = (enabled: boolean) =>
  useAppProductsCsvRetrieve({
    request: useAxiosConfig(),
    query: {
      enabled,
    },
  });

export const useGetPackaging = () =>
  useAppPackagingRetrieve({
    request: useAxiosConfig(),
  });

export const useGetPackagingDetail = (packagingUuid: string) =>
  useAppPackagingRetrieve2(packagingUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!packagingUuid,
    },
  });

export const useGetTransport = () =>
  useAppTransportList({
    request: useAxiosConfig(),
  });

export const useGetProcessing = () =>
  useAppProcessingList<ProcessingTypeList[]>({
    request: useAxiosConfig(),
  });

export const useGetCountries = () =>
  useAppAttributesCountriesList<Country[]>({
    request: useAxiosConfig(),
    query: {
      staleTime: Infinity,
    },
  });

export const useCompleteSignup = () => {
  const queryClient = useQueryClient();

  return useAppAccountCompleteSignupCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['account']);
      },
    },
  });
};

export const useGetFoodActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesFoodListParams
) => {
  return useCompanyReportsActivitiesFoodList<PaginatedFoodActivitiesList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useGetFoodActivity = ({
  foodActivityUuid,
  reportUuid,
  params,
}: {
  foodActivityUuid: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesFoodListParams;
}) => {
  return useCompanyReportsActivitiesFoodRetrieve<FoodActivity>(
    reportUuid,
    foodActivityUuid,
    params,
    {
      request: useAxiosConfig(),
    }
  );
};

export const useUpdateFoodActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesFoodUpdate<FoodActivityUpdate>({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          getCompanyReportsActivitiesFoodListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.removeQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};

export const useDeleteFoodActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          getCompanyReportsActivitiesFoodListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.removeQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};

export const useCreateCompanyReport = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsCreate({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['company-reports']);
      },
    },
  });
};

export const useGetCompanyReportsList = (
  params?: CompanyReportsActivitiesFoodListParams
) => {
  return useCompanyReportsList<PaginatedCompanyReportList>(params, {
    request: useAxiosConfig(),
  });
};

export const useGetCompanyReport = (companyReportUuid: string) => {
  return useCompanyReportsRetrieve<CompanyReportDetail>(companyReportUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!companyReportUuid,
    },
  });
};

export const useGetCompanyReportPeriods = (companyReportUuid: string) => {
  return useCompanyReportsTimePeriodsList(companyReportUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!companyReportUuid,
      staleTime: Infinity,
    },
  });
};

export const useCreateFoodImport = () => {
  return useCompanyReportsImportsFoodCreate({
    request: useAxiosConfig(),
  });
};

export const useGetCompanyReportImport = ({
  reportUuid,
  importUuid,
  polling,
}: {
  reportUuid: string;
  importUuid: string;
  polling?: boolean;
}) => {
  return useCompanyReportsImportsRetrieve(reportUuid, importUuid, {
    request: useAxiosConfig(),
    query: {
      enabled: !!importUuid,
      refetchInterval: polling && 1000,
      refetchIntervalInBackground: polling,
    },
  });
};

export const useGetActivityTypeUnits = (activityType: string) => {
  return useCompanyReportsActivityTypesUnitsList<ActivityUnit[]>(activityType, {
    request: useAxiosConfig(),
    query: {
      staleTime: Infinity,
    },
  });
};
