/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ActivityImport,
  ActivityImportFood,
  ActivityUnit,
  ActivityUpdateResponse,
  CompanyReport,
  CompanyReportDetail,
  CompanyReportsActivitiesElectricityListParams,
  CompanyReportsActivitiesFoodListParams,
  CompanyReportsActivitiesFoodRetrieveParams,
  CompanyReportsImportsListParams,
  CompanyReportsListParams,
  ElectricitySupply,
  FoodActivity,
  FoodActivityUpdate,
  PaginatedActivityImportList,
  PaginatedCompanyReportList,
  PaginatedElectricityActivityBaseList,
  PaginatedFoodActivitiesList,
  PatchedElectricitySupply,
  PatchedSite,
  Site,
  TimePeriod
} from '../../types'
import { apiClient } from '../../client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const companyReportsList = (
    params?: CompanyReportsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedCompanyReportList>(
      {url: `/company-reports/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsListQueryKey = (params?: CompanyReportsListParams,) => {
    return [`/company-reports/`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(params?: CompanyReportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsList>>> = ({ signal, pageParam }) => companyReportsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsList>>>
export type CompanyReportsListInfiniteQueryError = unknown

export const useCompanyReportsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(
 params?: CompanyReportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(params?: CompanyReportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsList>>> = ({ signal }) => companyReportsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsList>>>
export type CompanyReportsListQueryError = unknown

export const useCompanyReportsList = <TData = Awaited<ReturnType<typeof companyReportsList>>, TError = unknown>(
 params?: CompanyReportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsCreate = (
    companyReport: NonReadonly<CompanyReport>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<CompanyReport>(
      {url: `/company-reports/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyReport
    },
      options);
    }
  


export const getCompanyReportsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsCreate>>, TError,{data: NonReadonly<CompanyReport>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsCreate>>, TError,{data: NonReadonly<CompanyReport>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsCreate>>, {data: NonReadonly<CompanyReport>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsCreate>>>
    export type CompanyReportsCreateMutationBody = NonReadonly<CompanyReport>
    export type CompanyReportsCreateMutationError = unknown

    export const useCompanyReportsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsCreate>>, TError,{data: NonReadonly<CompanyReport>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsCreate>>,
        TError,
        {data: NonReadonly<CompanyReport>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsRetrieve = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CompanyReportDetail>(
      {url: `/company-reports/${companyReportUuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsRetrieveQueryKey = (companyReportUuid: string,) => {
    return [`/company-reports/${companyReportUuid}`] as const;
    }

    
export const getCompanyReportsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsRetrieve>>> = ({ signal }) => companyReportsRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsRetrieve>>>
export type CompanyReportsRetrieveInfiniteQueryError = unknown

export const useCompanyReportsRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsRetrieveInfiniteQueryOptions(companyReportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsRetrieveQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsRetrieve>>> = ({ signal }) => companyReportsRetrieve(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsRetrieve>>>
export type CompanyReportsRetrieveQueryError = unknown

export const useCompanyReportsRetrieve = <TData = Awaited<ReturnType<typeof companyReportsRetrieve>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsRetrieveQueryOptions(companyReportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesDestroy = (
    companyReportUuid: string,
    activityUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/${companyReportUuid}/activities/${activityUuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsActivitiesDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, TError,{companyReportUuid: string;activityUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, TError,{companyReportUuid: string;activityUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, {companyReportUuid: string;activityUuid: string}> = (props) => {
          const {companyReportUuid,activityUuid} = props ?? {};

          return  companyReportsActivitiesDestroy(companyReportUuid,activityUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>>
    
    export type CompanyReportsActivitiesDestroyMutationError = unknown

    export const useCompanyReportsActivitiesDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>, TError,{companyReportUuid: string;activityUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesDestroy>>,
        TError,
        {companyReportUuid: string;activityUuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsActivitiesElectricityList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedElectricityActivityBaseList>(
      {url: `/company-reports/${companyReportUuid}/activities/electricity`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesElectricityListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/electricity`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesElectricityListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesElectricityListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>> = ({ signal, pageParam }) => companyReportsActivitiesElectricityList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesElectricityListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>>
export type CompanyReportsActivitiesElectricityListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesElectricityListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesElectricityListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesElectricityListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesElectricityListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>> = ({ signal }) => companyReportsActivitiesElectricityList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesElectricityListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>>
export type CompanyReportsActivitiesElectricityListQueryError = unknown

export const useCompanyReportsActivitiesElectricityList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesElectricityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesElectricityList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesElectricityListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesFoodList = (
    companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedFoodActivitiesList>(
      {url: `/company-reports/${companyReportUuid}/activities/food`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesFoodListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/food`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesFoodListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>> = ({ signal, pageParam }) => companyReportsActivitiesFoodList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>>
export type CompanyReportsActivitiesFoodListInfiniteQueryError = unknown

export const useCompanyReportsActivitiesFoodListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesFoodListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>> = ({ signal }) => companyReportsActivitiesFoodList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>>
export type CompanyReportsActivitiesFoodListQueryError = unknown

export const useCompanyReportsActivitiesFoodList = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsActivitiesFoodListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesFoodRetrieve = (
    companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<FoodActivity>(
      {url: `/company-reports/${companyReportUuid}/activities/food/${activityUuid}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsActivitiesFoodRetrieveQueryKey = (companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams,) => {
    return [`/company-reports/${companyReportUuid}/activities/food/${activityUuid}`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsActivitiesFoodRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>> = ({ signal, pageParam }) => companyReportsActivitiesFoodRetrieve(companyReportUuid,activityUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>>
export type CompanyReportsActivitiesFoodRetrieveInfiniteQueryError = unknown

export const useCompanyReportsActivitiesFoodRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodRetrieveInfiniteQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivitiesFoodRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivitiesFoodRetrieveQueryKey(companyReportUuid,activityUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>> = ({ signal }) => companyReportsActivitiesFoodRetrieve(companyReportUuid,activityUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && activityUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivitiesFoodRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>>
export type CompanyReportsActivitiesFoodRetrieveQueryError = unknown

export const useCompanyReportsActivitiesFoodRetrieve = <TData = Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    activityUuid: string,
    params?: CompanyReportsActivitiesFoodRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivitiesFoodRetrieveQueryOptions(companyReportUuid,activityUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivitiesFoodUpdate = (
    companyReportUuid: string,
    activityUuid: string,
    foodActivityUpdate: NonReadonly<FoodActivityUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityUpdateResponse>(
      {url: `/company-reports/${companyReportUuid}/activities/food/${activityUuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: foodActivityUpdate
    },
      options);
    }
  


export const getCompanyReportsActivitiesFoodUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, {companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}> = (props) => {
          const {companyReportUuid,activityUuid,data} = props ?? {};

          return  companyReportsActivitiesFoodUpdate(companyReportUuid,activityUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsActivitiesFoodUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>>
    export type CompanyReportsActivitiesFoodUpdateMutationBody = NonReadonly<FoodActivityUpdate>
    export type CompanyReportsActivitiesFoodUpdateMutationError = unknown

    export const useCompanyReportsActivitiesFoodUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>, TError,{companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsActivitiesFoodUpdate>>,
        TError,
        {companyReportUuid: string;activityUuid: string;data: NonReadonly<FoodActivityUpdate>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsActivitiesFoodUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsImportsList = (
    companyReportUuid: string,
    params?: CompanyReportsImportsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedActivityImportList>(
      {url: `/company-reports/${companyReportUuid}/imports`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getCompanyReportsImportsListQueryKey = (companyReportUuid: string,
    params?: CompanyReportsImportsListParams,) => {
    return [`/company-reports/${companyReportUuid}/imports`, ...(params ? [params]: [])] as const;
    }

    
export const getCompanyReportsImportsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsList>>> = ({ signal, pageParam }) => companyReportsImportsList(companyReportUuid,{...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsList>>>
export type CompanyReportsImportsListInfiniteQueryError = unknown

export const useCompanyReportsImportsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsListInfiniteQueryOptions(companyReportUuid,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsImportsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsListQueryKey(companyReportUuid,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsList>>> = ({ signal }) => companyReportsImportsList(companyReportUuid,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsList>>>
export type CompanyReportsImportsListQueryError = unknown

export const useCompanyReportsImportsList = <TData = Awaited<ReturnType<typeof companyReportsImportsList>>, TError = unknown>(
 companyReportUuid: string,
    params?: CompanyReportsImportsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsListQueryOptions(companyReportUuid,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsImportsRetrieve = (
    companyReportUuid: string,
    importUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ActivityImport>(
      {url: `/company-reports/${companyReportUuid}/imports/${importUuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsImportsRetrieveQueryKey = (companyReportUuid: string,
    importUuid: string,) => {
    return [`/company-reports/${companyReportUuid}/imports/${importUuid}`] as const;
    }

    
export const getCompanyReportsImportsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(companyReportUuid: string,
    importUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsRetrieveQueryKey(companyReportUuid,importUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>> = ({ signal }) => companyReportsImportsRetrieve(companyReportUuid,importUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && importUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>>
export type CompanyReportsImportsRetrieveInfiniteQueryError = unknown

export const useCompanyReportsImportsRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    importUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsRetrieveInfiniteQueryOptions(companyReportUuid,importUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsImportsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(companyReportUuid: string,
    importUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsImportsRetrieveQueryKey(companyReportUuid,importUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>> = ({ signal }) => companyReportsImportsRetrieve(companyReportUuid,importUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid && importUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsImportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>>
export type CompanyReportsImportsRetrieveQueryError = unknown

export const useCompanyReportsImportsRetrieve = <TData = Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError = unknown>(
 companyReportUuid: string,
    importUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsImportsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsImportsRetrieveQueryOptions(companyReportUuid,importUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsImportsFoodCreate = (
    companyReportUuid: string,
    activityImportFood: NonReadonly<ActivityImportFood>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ActivityImport>(
      {url: `/company-reports/${companyReportUuid}/imports/food`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityImportFood
    },
      options);
    }
  


export const getCompanyReportsImportsFoodCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ActivityImportFood>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ActivityImportFood>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, {companyReportUuid: string;data: NonReadonly<ActivityImportFood>}> = (props) => {
          const {companyReportUuid,data} = props ?? {};

          return  companyReportsImportsFoodCreate(companyReportUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsImportsFoodCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>>
    export type CompanyReportsImportsFoodCreateMutationBody = NonReadonly<ActivityImportFood>
    export type CompanyReportsImportsFoodCreateMutationError = unknown

    export const useCompanyReportsImportsFoodCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>, TError,{companyReportUuid: string;data: NonReadonly<ActivityImportFood>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsImportsFoodCreate>>,
        TError,
        {companyReportUuid: string;data: NonReadonly<ActivityImportFood>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsImportsFoodCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsTimePeriodsList = (
    companyReportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<TimePeriod[]>(
      {url: `/company-reports/${companyReportUuid}/time-periods`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsTimePeriodsListQueryKey = (companyReportUuid: string,) => {
    return [`/company-reports/${companyReportUuid}/time-periods`] as const;
    }

    
export const getCompanyReportsTimePeriodsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsTimePeriodsListQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>> = ({ signal }) => companyReportsTimePeriodsList(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsTimePeriodsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>>
export type CompanyReportsTimePeriodsListInfiniteQueryError = unknown

export const useCompanyReportsTimePeriodsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsTimePeriodsListInfiniteQueryOptions(companyReportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsTimePeriodsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsTimePeriodsListQueryKey(companyReportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>> = ({ signal }) => companyReportsTimePeriodsList(companyReportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyReportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsTimePeriodsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>>
export type CompanyReportsTimePeriodsListQueryError = unknown

export const useCompanyReportsTimePeriodsList = <TData = Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError = unknown>(
 companyReportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsTimePeriodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsTimePeriodsListQueryOptions(companyReportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsActivityTypesUnitsList = (
    activityTypeSlug: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ActivityUnit[]>(
      {url: `/company-reports/activity-types/${activityTypeSlug}/units`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsActivityTypesUnitsListQueryKey = (activityTypeSlug: string,) => {
    return [`/company-reports/activity-types/${activityTypeSlug}/units`] as const;
    }

    
export const getCompanyReportsActivityTypesUnitsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(activityTypeSlug: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivityTypesUnitsListQueryKey(activityTypeSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>> = ({ signal }) => companyReportsActivityTypesUnitsList(activityTypeSlug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(activityTypeSlug),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivityTypesUnitsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>>
export type CompanyReportsActivityTypesUnitsListInfiniteQueryError = unknown

export const useCompanyReportsActivityTypesUnitsListInfinite = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(
 activityTypeSlug: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivityTypesUnitsListInfiniteQueryOptions(activityTypeSlug,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsActivityTypesUnitsListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(activityTypeSlug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsActivityTypesUnitsListQueryKey(activityTypeSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>> = ({ signal }) => companyReportsActivityTypesUnitsList(activityTypeSlug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(activityTypeSlug),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsActivityTypesUnitsListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>>
export type CompanyReportsActivityTypesUnitsListQueryError = unknown

export const useCompanyReportsActivityTypesUnitsList = <TData = Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError = unknown>(
 activityTypeSlug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsActivityTypesUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsActivityTypesUnitsListQueryOptions(activityTypeSlug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Site[]>(
      {url: `/company-reports/sites`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsSitesListQueryKey = () => {
    return [`/company-reports/sites`] as const;
    }

    
export const getCompanyReportsSitesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesList>>> = ({ signal }) => companyReportsSitesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesList>>>
export type CompanyReportsSitesListInfiniteQueryError = unknown

export const useCompanyReportsSitesListInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesList>>> = ({ signal }) => companyReportsSitesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesList>>>
export type CompanyReportsSitesListQueryError = unknown

export const useCompanyReportsSitesList = <TData = Awaited<ReturnType<typeof companyReportsSitesList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesCreate = (
    site: NonReadonly<Site>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: site
    },
      options);
    }
  


export const getCompanyReportsSitesCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesCreate>>, TError,{data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesCreate>>, TError,{data: NonReadonly<Site>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesCreate>>, {data: NonReadonly<Site>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsSitesCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesCreate>>>
    export type CompanyReportsSitesCreateMutationBody = NonReadonly<Site>
    export type CompanyReportsSitesCreateMutationError = unknown

    export const useCompanyReportsSitesCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesCreate>>, TError,{data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesCreate>>,
        TError,
        {data: NonReadonly<Site>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites/${uuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsSitesRetrieveQueryKey = (uuid: string,) => {
    return [`/company-reports/sites/${uuid}`] as const;
    }

    
export const getCompanyReportsSitesRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>> = ({ signal }) => companyReportsSitesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>>
export type CompanyReportsSitesRetrieveInfiniteQueryError = unknown

export const useCompanyReportsSitesRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>> = ({ signal }) => companyReportsSitesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>>
export type CompanyReportsSitesRetrieveQueryError = unknown

export const useCompanyReportsSitesRetrieve = <TData = Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesUpdate = (
    uuid: string,
    site: NonReadonly<Site>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites/${uuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: site
    },
      options);
    }
  


export const getCompanyReportsSitesUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, TError,{uuid: string;data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, TError,{uuid: string;data: NonReadonly<Site>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, {uuid: string;data: NonReadonly<Site>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesUpdate>>>
    export type CompanyReportsSitesUpdateMutationBody = NonReadonly<Site>
    export type CompanyReportsSitesUpdateMutationError = unknown

    export const useCompanyReportsSitesUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesUpdate>>, TError,{uuid: string;data: NonReadonly<Site>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<Site>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesPartialUpdate = (
    uuid: string,
    patchedSite: NonReadonly<PatchedSite>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site>(
      {url: `/company-reports/sites/${uuid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedSite
    },
      options);
    }
  


export const getCompanyReportsSitesPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedSite>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedSite>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, {uuid: string;data: NonReadonly<PatchedSite>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesPartialUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>>
    export type CompanyReportsSitesPartialUpdateMutationBody = NonReadonly<PatchedSite>
    export type CompanyReportsSitesPartialUpdateMutationError = unknown

    export const useCompanyReportsSitesPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedSite>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesPartialUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<PatchedSite>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesDestroy = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/sites/${uuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsSitesDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, TError,{uuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, {uuid: string}> = (props) => {
          const {uuid} = props ?? {};

          return  companyReportsSitesDestroy(uuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesDestroy>>>
    
    export type CompanyReportsSitesDestroyMutationError = unknown

    export const useCompanyReportsSitesDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesDestroy>>,
        TError,
        {uuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesBulkCreate = (
    site: NonReadonly<Site[]>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<Site[]>(
      {url: `/company-reports/sites/bulk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: site
    },
      options);
    }
  


export const getCompanyReportsSitesBulkCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, TError,{data: NonReadonly<Site[]>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, TError,{data: NonReadonly<Site[]>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, {data: NonReadonly<Site[]>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsSitesBulkCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesBulkCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>>
    export type CompanyReportsSitesBulkCreateMutationBody = NonReadonly<Site[]>
    export type CompanyReportsSitesBulkCreateMutationError = unknown

    export const useCompanyReportsSitesBulkCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>, TError,{data: NonReadonly<Site[]>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesBulkCreate>>,
        TError,
        {data: NonReadonly<Site[]>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesBulkCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ElectricitySupply[]>(
      {url: `/company-reports/sites/electricity-supplies`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsSitesElectricitySuppliesListQueryKey = () => {
    return [`/company-reports/sites/electricity-supplies`] as const;
    }

    
export const getCompanyReportsSitesElectricitySuppliesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>> = ({ signal }) => companyReportsSitesElectricitySuppliesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>>
export type CompanyReportsSitesElectricitySuppliesListInfiniteQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesListInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesElectricitySuppliesListQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>> = ({ signal }) => companyReportsSitesElectricitySuppliesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesListQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>>
export type CompanyReportsSitesElectricitySuppliesListQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesList = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesElectricitySuppliesCreate = (
    electricitySupply: NonReadonly<ElectricitySupply>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: electricitySupply
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, TError,{data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, TError,{data: NonReadonly<ElectricitySupply>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, {data: NonReadonly<ElectricitySupply>}> = (props) => {
          const {data} = props ?? {};

          return  companyReportsSitesElectricitySuppliesCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>>
    export type CompanyReportsSitesElectricitySuppliesCreateMutationBody = NonReadonly<ElectricitySupply>
    export type CompanyReportsSitesElectricitySuppliesCreateMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>, TError,{data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesCreate>>,
        TError,
        {data: NonReadonly<ElectricitySupply>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyReportsSitesElectricitySuppliesRetrieveQueryKey = (uuid: string,) => {
    return [`/company-reports/sites/electricity-supplies/${uuid}`] as const;
    }

    
export const getCompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>> = ({ signal }) => companyReportsSitesElectricitySuppliesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>>
export type CompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesRetrieveInfinite = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCompanyReportsSitesElectricitySuppliesRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyReportsSitesElectricitySuppliesRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>> = ({ signal }) => companyReportsSitesElectricitySuppliesRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type CompanyReportsSitesElectricitySuppliesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>>
export type CompanyReportsSitesElectricitySuppliesRetrieveQueryError = unknown

export const useCompanyReportsSitesElectricitySuppliesRetrieve = <TData = Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCompanyReportsSitesElectricitySuppliesRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const companyReportsSitesElectricitySuppliesUpdate = (
    uuid: string,
    electricitySupply: NonReadonly<ElectricitySupply>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: electricitySupply
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, TError,{uuid: string;data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, TError,{uuid: string;data: NonReadonly<ElectricitySupply>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, {uuid: string;data: NonReadonly<ElectricitySupply>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesElectricitySuppliesUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>>
    export type CompanyReportsSitesElectricitySuppliesUpdateMutationBody = NonReadonly<ElectricitySupply>
    export type CompanyReportsSitesElectricitySuppliesUpdateMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>, TError,{uuid: string;data: NonReadonly<ElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<ElectricitySupply>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesPartialUpdate = (
    uuid: string,
    patchedElectricitySupply: NonReadonly<PatchedElectricitySupply>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ElectricitySupply>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedElectricitySupply
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedElectricitySupply>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, {uuid: string;data: NonReadonly<PatchedElectricitySupply>}> = (props) => {
          const {uuid,data} = props ?? {};

          return  companyReportsSitesElectricitySuppliesPartialUpdate(uuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>>
    export type CompanyReportsSitesElectricitySuppliesPartialUpdateMutationBody = NonReadonly<PatchedElectricitySupply>
    export type CompanyReportsSitesElectricitySuppliesPartialUpdateMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>, TError,{uuid: string;data: NonReadonly<PatchedElectricitySupply>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesPartialUpdate>>,
        TError,
        {uuid: string;data: NonReadonly<PatchedElectricitySupply>},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const companyReportsSitesElectricitySuppliesDestroy = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/company-reports/sites/electricity-supplies/${uuid}`, method: 'DELETE'
    },
      options);
    }
  


export const getCompanyReportsSitesElectricitySuppliesDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, TError,{uuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, {uuid: string}> = (props) => {
          const {uuid} = props ?? {};

          return  companyReportsSitesElectricitySuppliesDestroy(uuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompanyReportsSitesElectricitySuppliesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>>
    
    export type CompanyReportsSitesElectricitySuppliesDestroyMutationError = unknown

    export const useCompanyReportsSitesElectricitySuppliesDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof companyReportsSitesElectricitySuppliesDestroy>>,
        TError,
        {uuid: string},
        TContext
      > => {

      const mutationOptions = getCompanyReportsSitesElectricitySuppliesDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    