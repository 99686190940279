import { Button } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { Column } from '@tanstack/react-table';
import { ClassValue } from 'clsx';

import { ArrowDown, ArrowUp } from 'lucide-react';
import { ReactNode } from 'react';

interface HeaderCellProps<TData, TValue> {
  column: Column<TData, TValue>;
  name: string | ReactNode;
  unit?: ReactNode;
  className?: ClassValue;
  textWhite?: boolean;
  numberColumn?: boolean;
  unitWrap?: boolean;
}

export function HeaderCell<TData, TValue>({
  column,
  name,
  className,
  unit,
  textWhite = false,
  numberColumn = false,
  unitWrap = true,
}: HeaderCellProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return (
      <div
        className={cn(
          'flex flex-wrap items-center',
          {
            'text-gray-00': textWhite,
            'justify-end': numberColumn,
            'flex-nowrap': !unitWrap,
          },
          className
        )}
      >
        <p>{name}</p>
        {unit && <p className="ml-1 text-xs">{unit}</p>}
      </div>
    );
  }

  return (
    <Button
      variant="link"
      onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      className={cn(
        'my-3 w-full items-center p-0 text-muted-foreground hover:text-muted-foreground/75 hover:no-underline',
        {
          'font-bold':
            column.getIsSorted() === 'desc' || column.getIsSorted() === 'asc',
          'text-gray-00': textWhite,
        },
        className
      )}
    >
      <div
        className={cn('flex flex-wrap w-full items-center', {
          'justify-end': numberColumn,
          'flex-nowrap': !unitWrap,
        })}
      >
        <p>{name}</p>
        {unit && <p className="ml-1 text-xs">{unit}</p>}
      </div>
      {column.getIsSorted() === 'desc' && (
        <ArrowDown strokeWidth={2.5} className="ml-2 size-4 font-bold" />
      )}
      {column.getIsSorted() === 'asc' && (
        <ArrowUp strokeWidth={2.5} className="ml-2 size-4 font-bold" />
      )}
    </Button>
  );
}
