import { roundNumberToNDecimalPlaces } from '@app/utils/helpers';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface NumberCellProps {
  number: number;
  className?: ClassValue;
  rounded?: boolean;
}

export function NumberCell({ number, className, rounded }: NumberCellProps) {
  return (
    <p className={cn('text-right', className)}>
      {rounded ? roundNumberToNDecimalPlaces(number, 2) : number.toFixed()}
    </p>
  );
}
