/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `base_food` - BaseFood
* `product` - Product
 */
export type ProductIngredientEntityTypeEnum = typeof ProductIngredientEntityTypeEnum[keyof typeof ProductIngredientEntityTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductIngredientEntityTypeEnum = {
  base_food: 'base_food',
  product: 'product',
} as const;
