/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AccountCompleteSignup,
  AccountCreate,
  AccountCreateResponse,
  AccountResponse,
  AccountVerifyEmail,
  AppAvailableIngredientsListParams,
  AppAvailablePackagingListParams,
  AppAvailableProcessingListParams,
  AppAvailableTransportListParams,
  AppFoodsListParams,
  AppOrganizationFoodsListParams,
  AppProductsListParams,
  AppShareQueryRetrieveParams,
  AssessRequest,
  AssessResponse,
  AvailableIngredients,
  AvailablePackaging,
  AvailableProcessing,
  AvailableTransport,
  BaseFoodList,
  CollectionCreateUpdate,
  CollectionDetail,
  CollectionList,
  IngredientChoices,
  IngredientCreateUpdate,
  IngredientInsightsList,
  IngredientList,
  JourneyGroupCreate,
  JourneyGroupList,
  JourneyGroupUpdate,
  Login,
  MetricsList,
  MyPasswordReset,
  OrganizationFoodCreateUpdate,
  OrganizationFoodList,
  PackagingTypeCreate,
  PackagingTypeFileFinish,
  PackagingTypeFileRetrieve,
  PackagingTypeUpdate,
  PaginatedOrganizationFoodListList,
  PaginatedProductListList,
  PasswordChange,
  PasswordResetConfirm,
  PatchedCollectionCreateUpdate,
  PatchedIngredientCreateUpdate,
  PatchedJourneyGroupUpdate,
  PatchedOrganizationFoodCreateUpdate,
  PatchedProcessingTypeUpdate,
  PatchedProductCreateUpdate,
  PatchedProductPackagingTypeCreateUpdate,
  PatchedProductProcessingTypeCreateUpdate,
  PatchedProductTransportTypeCreateUpdate,
  ProcessingTypeCreate,
  ProcessingTypeFileFinish,
  ProcessingTypeFileRetrieve,
  ProcessingTypeList,
  ProcessingTypeUpdate,
  ProductCreateUpdate,
  ProductDetail,
  ProductPackagingTypeCreateUpdate,
  ProductPackagingTypeList,
  ProductProcessingTypeCreateUpdate,
  ProductProcessingTypeList,
  ProductResult,
  ProductTransportTypeCreateUpdate,
  ProductTransportTypeList,
  Report,
  ReportOrganizationFoodDetail,
  ReportProductDetail,
  SharedLinkResponse,
  SharedLinkVerifyResponse,
  TargetCreate,
  TargetList,
  TokenRefresh,
  TokenVerify,
  TransportTypeFileFinish,
  TransportTypeFileRetrieve,
  Unit
} from '../../types'
import { apiClient } from '../../client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Create or retrieve the user's account.
 */
export const appAccountRetrieve = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<AccountResponse>(
      {url: `/app/account/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppAccountRetrieveQueryKey = () => {
    return [`/app/account/`] as const;
    }

    
export const getAppAccountRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appAccountRetrieve>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAccountRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAccountRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAccountRetrieve>>> = ({ signal }) => appAccountRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAccountRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAccountRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appAccountRetrieve>>>
export type AppAccountRetrieveInfiniteQueryError = unknown

export const useAppAccountRetrieveInfinite = <TData = Awaited<ReturnType<typeof appAccountRetrieve>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAccountRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAccountRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAccountRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appAccountRetrieve>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAccountRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAccountRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAccountRetrieve>>> = ({ signal }) => appAccountRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appAccountRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAccountRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appAccountRetrieve>>>
export type AppAccountRetrieveQueryError = unknown

export const useAppAccountRetrieve = <TData = Awaited<ReturnType<typeof appAccountRetrieve>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAccountRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAccountRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create or retrieve the user's account.
 */
export const appAccountCreate = (
    accountCreate: AccountCreate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<AccountCreateResponse>(
      {url: `/app/account/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accountCreate
    },
      options);
    }
  


export const getAppAccountCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountCreate>>, TError,{data: AccountCreate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appAccountCreate>>, TError,{data: AccountCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appAccountCreate>>, {data: AccountCreate}> = (props) => {
          const {data} = props ?? {};

          return  appAccountCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppAccountCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appAccountCreate>>>
    export type AppAccountCreateMutationBody = AccountCreate
    export type AppAccountCreateMutationError = unknown

    export const useAppAccountCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountCreate>>, TError,{data: AccountCreate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appAccountCreate>>,
        TError,
        {data: AccountCreate},
        TContext
      > => {

      const mutationOptions = getAppAccountCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Complete the user signup
 */
export const appAccountCompleteSignupCreate = (
    accountCompleteSignup: AccountCompleteSignup,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<AccountCompleteSignup>(
      {url: `/app/account/complete-signup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accountCompleteSignup
    },
      options);
    }
  


export const getAppAccountCompleteSignupCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountCompleteSignupCreate>>, TError,{data: AccountCompleteSignup}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appAccountCompleteSignupCreate>>, TError,{data: AccountCompleteSignup}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appAccountCompleteSignupCreate>>, {data: AccountCompleteSignup}> = (props) => {
          const {data} = props ?? {};

          return  appAccountCompleteSignupCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppAccountCompleteSignupCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appAccountCompleteSignupCreate>>>
    export type AppAccountCompleteSignupCreateMutationBody = AccountCompleteSignup
    export type AppAccountCompleteSignupCreateMutationError = unknown

    export const useAppAccountCompleteSignupCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountCompleteSignupCreate>>, TError,{data: AccountCompleteSignup}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appAccountCompleteSignupCreate>>,
        TError,
        {data: AccountCompleteSignup},
        TContext
      > => {

      const mutationOptions = getAppAccountCompleteSignupCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Resend the email verification email.
 */
export const appAccountResendEmailVerificationCreate = (
    
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/account/resend-email-verification`, method: 'POST'
    },
      options);
    }
  


export const getAppAccountResendEmailVerificationCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountResendEmailVerificationCreate>>, TError,void, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appAccountResendEmailVerificationCreate>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appAccountResendEmailVerificationCreate>>, void> = () => {
          

          return  appAccountResendEmailVerificationCreate(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppAccountResendEmailVerificationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appAccountResendEmailVerificationCreate>>>
    
    export type AppAccountResendEmailVerificationCreateMutationError = unknown

    export const useAppAccountResendEmailVerificationCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountResendEmailVerificationCreate>>, TError,void, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appAccountResendEmailVerificationCreate>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getAppAccountResendEmailVerificationCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Verify the user's email.
 */
export const appAccountVerifyEmailCreate = (
    accountVerifyEmail: AccountVerifyEmail,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/account/verify-email`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accountVerifyEmail
    },
      options);
    }
  


export const getAppAccountVerifyEmailCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountVerifyEmailCreate>>, TError,{data: AccountVerifyEmail}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appAccountVerifyEmailCreate>>, TError,{data: AccountVerifyEmail}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appAccountVerifyEmailCreate>>, {data: AccountVerifyEmail}> = (props) => {
          const {data} = props ?? {};

          return  appAccountVerifyEmailCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppAccountVerifyEmailCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appAccountVerifyEmailCreate>>>
    export type AppAccountVerifyEmailCreateMutationBody = AccountVerifyEmail
    export type AppAccountVerifyEmailCreateMutationError = unknown

    export const useAppAccountVerifyEmailCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appAccountVerifyEmailCreate>>, TError,{data: AccountVerifyEmail}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appAccountVerifyEmailCreate>>,
        TError,
        {data: AccountVerifyEmail},
        TContext
      > => {

      const mutationOptions = getAppAccountVerifyEmailCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appAttributesCountriesList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<unknown>(
      {url: `/app/attributes/countries/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppAttributesCountriesListQueryKey = () => {
    return [`/app/attributes/countries/`] as const;
    }

    
export const getAppAttributesCountriesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appAttributesCountriesList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAttributesCountriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAttributesCountriesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAttributesCountriesList>>> = ({ signal }) => appAttributesCountriesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAttributesCountriesList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAttributesCountriesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appAttributesCountriesList>>>
export type AppAttributesCountriesListInfiniteQueryError = unknown

export const useAppAttributesCountriesListInfinite = <TData = Awaited<ReturnType<typeof appAttributesCountriesList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAttributesCountriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAttributesCountriesListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAttributesCountriesListQueryOptions = <TData = Awaited<ReturnType<typeof appAttributesCountriesList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAttributesCountriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAttributesCountriesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAttributesCountriesList>>> = ({ signal }) => appAttributesCountriesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appAttributesCountriesList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAttributesCountriesListQueryResult = NonNullable<Awaited<ReturnType<typeof appAttributesCountriesList>>>
export type AppAttributesCountriesListQueryError = unknown

export const useAppAttributesCountriesList = <TData = Awaited<ReturnType<typeof appAttributesCountriesList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAttributesCountriesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAttributesCountriesListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appAvailableIngredientsList = (
    params?: AppAvailableIngredientsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<AvailableIngredients[]>(
      {url: `/app/available_ingredients/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppAvailableIngredientsListQueryKey = (params?: AppAvailableIngredientsListParams,) => {
    return [`/app/available_ingredients/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppAvailableIngredientsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError = unknown>(params?: AppAvailableIngredientsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailableIngredientsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailableIngredientsList>>> = ({ signal, pageParam }) => appAvailableIngredientsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailableIngredientsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailableIngredientsList>>>
export type AppAvailableIngredientsListInfiniteQueryError = unknown

export const useAppAvailableIngredientsListInfinite = <TData = Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError = unknown>(
 params?: AppAvailableIngredientsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailableIngredientsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAvailableIngredientsListQueryOptions = <TData = Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError = unknown>(params?: AppAvailableIngredientsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailableIngredientsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailableIngredientsList>>> = ({ signal }) => appAvailableIngredientsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailableIngredientsListQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailableIngredientsList>>>
export type AppAvailableIngredientsListQueryError = unknown

export const useAppAvailableIngredientsList = <TData = Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError = unknown>(
 params?: AppAvailableIngredientsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailableIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailableIngredientsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appAvailablePackagingList = (
    params?: AppAvailablePackagingListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<AvailablePackaging[]>(
      {url: `/app/available_packaging/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppAvailablePackagingListQueryKey = (params?: AppAvailablePackagingListParams,) => {
    return [`/app/available_packaging/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppAvailablePackagingListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appAvailablePackagingList>>, TError = unknown>(params?: AppAvailablePackagingListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailablePackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailablePackagingListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailablePackagingList>>> = ({ signal, pageParam }) => appAvailablePackagingList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailablePackagingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailablePackagingListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailablePackagingList>>>
export type AppAvailablePackagingListInfiniteQueryError = unknown

export const useAppAvailablePackagingListInfinite = <TData = Awaited<ReturnType<typeof appAvailablePackagingList>>, TError = unknown>(
 params?: AppAvailablePackagingListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailablePackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailablePackagingListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAvailablePackagingListQueryOptions = <TData = Awaited<ReturnType<typeof appAvailablePackagingList>>, TError = unknown>(params?: AppAvailablePackagingListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailablePackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailablePackagingListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailablePackagingList>>> = ({ signal }) => appAvailablePackagingList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appAvailablePackagingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailablePackagingListQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailablePackagingList>>>
export type AppAvailablePackagingListQueryError = unknown

export const useAppAvailablePackagingList = <TData = Awaited<ReturnType<typeof appAvailablePackagingList>>, TError = unknown>(
 params?: AppAvailablePackagingListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailablePackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailablePackagingListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appAvailableProcessingList = (
    params?: AppAvailableProcessingListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<AvailableProcessing[]>(
      {url: `/app/available_processing/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppAvailableProcessingListQueryKey = (params?: AppAvailableProcessingListParams,) => {
    return [`/app/available_processing/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppAvailableProcessingListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appAvailableProcessingList>>, TError = unknown>(params?: AppAvailableProcessingListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailableProcessingListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailableProcessingList>>> = ({ signal, pageParam }) => appAvailableProcessingList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableProcessingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailableProcessingListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailableProcessingList>>>
export type AppAvailableProcessingListInfiniteQueryError = unknown

export const useAppAvailableProcessingListInfinite = <TData = Awaited<ReturnType<typeof appAvailableProcessingList>>, TError = unknown>(
 params?: AppAvailableProcessingListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailableProcessingListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAvailableProcessingListQueryOptions = <TData = Awaited<ReturnType<typeof appAvailableProcessingList>>, TError = unknown>(params?: AppAvailableProcessingListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailableProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailableProcessingListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailableProcessingList>>> = ({ signal }) => appAvailableProcessingList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appAvailableProcessingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailableProcessingListQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailableProcessingList>>>
export type AppAvailableProcessingListQueryError = unknown

export const useAppAvailableProcessingList = <TData = Awaited<ReturnType<typeof appAvailableProcessingList>>, TError = unknown>(
 params?: AppAvailableProcessingListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailableProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailableProcessingListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appAvailableTransportList = (
    params?: AppAvailableTransportListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<AvailableTransport[]>(
      {url: `/app/available_transport/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppAvailableTransportListQueryKey = (params?: AppAvailableTransportListParams,) => {
    return [`/app/available_transport/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppAvailableTransportListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appAvailableTransportList>>, TError = unknown>(params?: AppAvailableTransportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailableTransportListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailableTransportList>>> = ({ signal, pageParam }) => appAvailableTransportList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableTransportList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailableTransportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailableTransportList>>>
export type AppAvailableTransportListInfiniteQueryError = unknown

export const useAppAvailableTransportListInfinite = <TData = Awaited<ReturnType<typeof appAvailableTransportList>>, TError = unknown>(
 params?: AppAvailableTransportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appAvailableTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailableTransportListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppAvailableTransportListQueryOptions = <TData = Awaited<ReturnType<typeof appAvailableTransportList>>, TError = unknown>(params?: AppAvailableTransportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailableTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppAvailableTransportListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appAvailableTransportList>>> = ({ signal }) => appAvailableTransportList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appAvailableTransportList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppAvailableTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof appAvailableTransportList>>>
export type AppAvailableTransportListQueryError = unknown

export const useAppAvailableTransportList = <TData = Awaited<ReturnType<typeof appAvailableTransportList>>, TError = unknown>(
 params?: AppAvailableTransportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appAvailableTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppAvailableTransportListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appCollectionsList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CollectionList[]>(
      {url: `/app/collections/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppCollectionsListQueryKey = () => {
    return [`/app/collections/`] as const;
    }

    
export const getAppCollectionsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appCollectionsList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appCollectionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppCollectionsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appCollectionsList>>> = ({ signal }) => appCollectionsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appCollectionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppCollectionsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appCollectionsList>>>
export type AppCollectionsListInfiniteQueryError = unknown

export const useAppCollectionsListInfinite = <TData = Awaited<ReturnType<typeof appCollectionsList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appCollectionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppCollectionsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppCollectionsListQueryOptions = <TData = Awaited<ReturnType<typeof appCollectionsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appCollectionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppCollectionsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appCollectionsList>>> = ({ signal }) => appCollectionsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appCollectionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppCollectionsListQueryResult = NonNullable<Awaited<ReturnType<typeof appCollectionsList>>>
export type AppCollectionsListQueryError = unknown

export const useAppCollectionsList = <TData = Awaited<ReturnType<typeof appCollectionsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appCollectionsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppCollectionsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appCollectionsCreate = (
    collectionCreateUpdate: NonReadonly<CollectionCreateUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<CollectionCreateUpdate>(
      {url: `/app/collections/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: collectionCreateUpdate
    },
      options);
    }
  


export const getAppCollectionsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsCreate>>, TError,{data: NonReadonly<CollectionCreateUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appCollectionsCreate>>, TError,{data: NonReadonly<CollectionCreateUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appCollectionsCreate>>, {data: NonReadonly<CollectionCreateUpdate>}> = (props) => {
          const {data} = props ?? {};

          return  appCollectionsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppCollectionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appCollectionsCreate>>>
    export type AppCollectionsCreateMutationBody = NonReadonly<CollectionCreateUpdate>
    export type AppCollectionsCreateMutationError = unknown

    export const useAppCollectionsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsCreate>>, TError,{data: NonReadonly<CollectionCreateUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appCollectionsCreate>>,
        TError,
        {data: NonReadonly<CollectionCreateUpdate>},
        TContext
      > => {

      const mutationOptions = getAppCollectionsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appCollectionsRetrieve = (
    collectionUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CollectionDetail>(
      {url: `/app/collections/${collectionUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppCollectionsRetrieveQueryKey = (collectionUuid: string,) => {
    return [`/app/collections/${collectionUuid}/`] as const;
    }

    
export const getAppCollectionsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError = unknown>(collectionUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppCollectionsRetrieveQueryKey(collectionUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appCollectionsRetrieve>>> = ({ signal }) => appCollectionsRetrieve(collectionUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppCollectionsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appCollectionsRetrieve>>>
export type AppCollectionsRetrieveInfiniteQueryError = unknown

export const useAppCollectionsRetrieveInfinite = <TData = Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError = unknown>(
 collectionUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppCollectionsRetrieveInfiniteQueryOptions(collectionUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppCollectionsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError = unknown>(collectionUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppCollectionsRetrieveQueryKey(collectionUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appCollectionsRetrieve>>> = ({ signal }) => appCollectionsRetrieve(collectionUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppCollectionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appCollectionsRetrieve>>>
export type AppCollectionsRetrieveQueryError = unknown

export const useAppCollectionsRetrieve = <TData = Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError = unknown>(
 collectionUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appCollectionsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppCollectionsRetrieveQueryOptions(collectionUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appCollectionsUpdate = (
    collectionUuid: string,
    collectionCreateUpdate: NonReadonly<CollectionCreateUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<CollectionCreateUpdate>(
      {url: `/app/collections/${collectionUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: collectionCreateUpdate
    },
      options);
    }
  


export const getAppCollectionsUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsUpdate>>, TError,{collectionUuid: string;data: NonReadonly<CollectionCreateUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appCollectionsUpdate>>, TError,{collectionUuid: string;data: NonReadonly<CollectionCreateUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appCollectionsUpdate>>, {collectionUuid: string;data: NonReadonly<CollectionCreateUpdate>}> = (props) => {
          const {collectionUuid,data} = props ?? {};

          return  appCollectionsUpdate(collectionUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppCollectionsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appCollectionsUpdate>>>
    export type AppCollectionsUpdateMutationBody = NonReadonly<CollectionCreateUpdate>
    export type AppCollectionsUpdateMutationError = unknown

    export const useAppCollectionsUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsUpdate>>, TError,{collectionUuid: string;data: NonReadonly<CollectionCreateUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appCollectionsUpdate>>,
        TError,
        {collectionUuid: string;data: NonReadonly<CollectionCreateUpdate>},
        TContext
      > => {

      const mutationOptions = getAppCollectionsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appCollectionsPartialUpdate = (
    collectionUuid: string,
    patchedCollectionCreateUpdate: NonReadonly<PatchedCollectionCreateUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<CollectionCreateUpdate>(
      {url: `/app/collections/${collectionUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedCollectionCreateUpdate
    },
      options);
    }
  


export const getAppCollectionsPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsPartialUpdate>>, TError,{collectionUuid: string;data: NonReadonly<PatchedCollectionCreateUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appCollectionsPartialUpdate>>, TError,{collectionUuid: string;data: NonReadonly<PatchedCollectionCreateUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appCollectionsPartialUpdate>>, {collectionUuid: string;data: NonReadonly<PatchedCollectionCreateUpdate>}> = (props) => {
          const {collectionUuid,data} = props ?? {};

          return  appCollectionsPartialUpdate(collectionUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppCollectionsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appCollectionsPartialUpdate>>>
    export type AppCollectionsPartialUpdateMutationBody = NonReadonly<PatchedCollectionCreateUpdate>
    export type AppCollectionsPartialUpdateMutationError = unknown

    export const useAppCollectionsPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsPartialUpdate>>, TError,{collectionUuid: string;data: NonReadonly<PatchedCollectionCreateUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appCollectionsPartialUpdate>>,
        TError,
        {collectionUuid: string;data: NonReadonly<PatchedCollectionCreateUpdate>},
        TContext
      > => {

      const mutationOptions = getAppCollectionsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appCollectionsDestroy = (
    collectionUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/collections/${collectionUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppCollectionsDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsDestroy>>, TError,{collectionUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appCollectionsDestroy>>, TError,{collectionUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appCollectionsDestroy>>, {collectionUuid: string}> = (props) => {
          const {collectionUuid} = props ?? {};

          return  appCollectionsDestroy(collectionUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppCollectionsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appCollectionsDestroy>>>
    
    export type AppCollectionsDestroyMutationError = unknown

    export const useAppCollectionsDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appCollectionsDestroy>>, TError,{collectionUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appCollectionsDestroy>>,
        TError,
        {collectionUuid: string},
        TContext
      > => {

      const mutationOptions = getAppCollectionsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appFoodsList = (
    params?: AppFoodsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<BaseFoodList[]>(
      {url: `/app/foods/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppFoodsListQueryKey = (params?: AppFoodsListParams,) => {
    return [`/app/foods/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppFoodsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appFoodsList>>, TError = unknown>(params?: AppFoodsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppFoodsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appFoodsList>>> = ({ signal, pageParam }) => appFoodsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appFoodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppFoodsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appFoodsList>>>
export type AppFoodsListInfiniteQueryError = unknown

export const useAppFoodsListInfinite = <TData = Awaited<ReturnType<typeof appFoodsList>>, TError = unknown>(
 params?: AppFoodsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppFoodsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppFoodsListQueryOptions = <TData = Awaited<ReturnType<typeof appFoodsList>>, TError = unknown>(params?: AppFoodsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppFoodsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appFoodsList>>> = ({ signal }) => appFoodsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appFoodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppFoodsListQueryResult = NonNullable<Awaited<ReturnType<typeof appFoodsList>>>
export type AppFoodsListQueryError = unknown

export const useAppFoodsList = <TData = Awaited<ReturnType<typeof appFoodsList>>, TError = unknown>(
 params?: AppFoodsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppFoodsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appFoodsInsightsList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<IngredientInsightsList[]>(
      {url: `/app/foods/insights/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppFoodsInsightsListQueryKey = () => {
    return [`/app/foods/insights/`] as const;
    }

    
export const getAppFoodsInsightsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appFoodsInsightsList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appFoodsInsightsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppFoodsInsightsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appFoodsInsightsList>>> = ({ signal }) => appFoodsInsightsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appFoodsInsightsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppFoodsInsightsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appFoodsInsightsList>>>
export type AppFoodsInsightsListInfiniteQueryError = unknown

export const useAppFoodsInsightsListInfinite = <TData = Awaited<ReturnType<typeof appFoodsInsightsList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appFoodsInsightsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppFoodsInsightsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppFoodsInsightsListQueryOptions = <TData = Awaited<ReturnType<typeof appFoodsInsightsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appFoodsInsightsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppFoodsInsightsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appFoodsInsightsList>>> = ({ signal }) => appFoodsInsightsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appFoodsInsightsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppFoodsInsightsListQueryResult = NonNullable<Awaited<ReturnType<typeof appFoodsInsightsList>>>
export type AppFoodsInsightsListQueryError = unknown

export const useAppFoodsInsightsList = <TData = Awaited<ReturnType<typeof appFoodsInsightsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appFoodsInsightsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppFoodsInsightsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appHomeRetrieve = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<void>(
      {url: `/app/home/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppHomeRetrieveQueryKey = () => {
    return [`/app/home/`] as const;
    }

    
export const getAppHomeRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appHomeRetrieve>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appHomeRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppHomeRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appHomeRetrieve>>> = ({ signal }) => appHomeRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appHomeRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppHomeRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appHomeRetrieve>>>
export type AppHomeRetrieveInfiniteQueryError = unknown

export const useAppHomeRetrieveInfinite = <TData = Awaited<ReturnType<typeof appHomeRetrieve>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appHomeRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppHomeRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppHomeRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appHomeRetrieve>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appHomeRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppHomeRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appHomeRetrieve>>> = ({ signal }) => appHomeRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appHomeRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppHomeRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appHomeRetrieve>>>
export type AppHomeRetrieveQueryError = unknown

export const useAppHomeRetrieve = <TData = Awaited<ReturnType<typeof appHomeRetrieve>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appHomeRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppHomeRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appIngredientChoicesList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<IngredientChoices[]>(
      {url: `/app/ingredient_choices/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppIngredientChoicesListQueryKey = () => {
    return [`/app/ingredient_choices/`] as const;
    }

    
export const getAppIngredientChoicesListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appIngredientChoicesList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appIngredientChoicesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppIngredientChoicesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appIngredientChoicesList>>> = ({ signal }) => appIngredientChoicesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appIngredientChoicesList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppIngredientChoicesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appIngredientChoicesList>>>
export type AppIngredientChoicesListInfiniteQueryError = unknown

export const useAppIngredientChoicesListInfinite = <TData = Awaited<ReturnType<typeof appIngredientChoicesList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appIngredientChoicesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppIngredientChoicesListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppIngredientChoicesListQueryOptions = <TData = Awaited<ReturnType<typeof appIngredientChoicesList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appIngredientChoicesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppIngredientChoicesListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appIngredientChoicesList>>> = ({ signal }) => appIngredientChoicesList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appIngredientChoicesList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppIngredientChoicesListQueryResult = NonNullable<Awaited<ReturnType<typeof appIngredientChoicesList>>>
export type AppIngredientChoicesListQueryError = unknown

export const useAppIngredientChoicesList = <TData = Awaited<ReturnType<typeof appIngredientChoicesList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appIngredientChoicesList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppIngredientChoicesListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Check the credentials and return the REST Token
if the credentials are valid and authenticated.
Calls Django Auth login method to register User ID
in Django session framework

Accept the following POST parameters: username, password
Return the REST Framework Token Object's key.
 */
export const appLoginCreate = (
    login: Login,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/login/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: login
    },
      options);
    }
  


export const getAppLoginCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appLoginCreate>>, TError,{data: Login}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appLoginCreate>>, TError,{data: Login}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appLoginCreate>>, {data: Login}> = (props) => {
          const {data} = props ?? {};

          return  appLoginCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppLoginCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appLoginCreate>>>
    export type AppLoginCreateMutationBody = Login
    export type AppLoginCreateMutationError = unknown

    export const useAppLoginCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appLoginCreate>>, TError,{data: Login}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appLoginCreate>>,
        TError,
        {data: Login},
        TContext
      > => {

      const mutationOptions = getAppLoginCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Calls Django logout method and delete the Token object
assigned to the current User object.

Accepts/Returns nothing.
 */
export const appLogoutCreate = (
    
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/logout/`, method: 'POST'
    },
      options);
    }
  


export const getAppLogoutCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appLogoutCreate>>, TError,void, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appLogoutCreate>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appLogoutCreate>>, void> = () => {
          

          return  appLogoutCreate(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppLogoutCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appLogoutCreate>>>
    
    export type AppLogoutCreateMutationError = unknown

    export const useAppLogoutCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appLogoutCreate>>, TError,void, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appLogoutCreate>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getAppLogoutCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appOrganizationFoodsList = (
    params?: AppOrganizationFoodsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedOrganizationFoodListList>(
      {url: `/app/organization_foods/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppOrganizationFoodsListQueryKey = (params?: AppOrganizationFoodsListParams,) => {
    return [`/app/organization_foods/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppOrganizationFoodsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError = unknown>(params?: AppOrganizationFoodsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppOrganizationFoodsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appOrganizationFoodsList>>> = ({ signal, pageParam }) => appOrganizationFoodsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppOrganizationFoodsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsList>>>
export type AppOrganizationFoodsListInfiniteQueryError = unknown

export const useAppOrganizationFoodsListInfinite = <TData = Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError = unknown>(
 params?: AppOrganizationFoodsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppOrganizationFoodsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppOrganizationFoodsListQueryOptions = <TData = Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError = unknown>(params?: AppOrganizationFoodsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppOrganizationFoodsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appOrganizationFoodsList>>> = ({ signal }) => appOrganizationFoodsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppOrganizationFoodsListQueryResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsList>>>
export type AppOrganizationFoodsListQueryError = unknown

export const useAppOrganizationFoodsList = <TData = Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError = unknown>(
 params?: AppOrganizationFoodsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppOrganizationFoodsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appOrganizationFoodsCreate = (
    organizationFoodCreateUpdate: OrganizationFoodCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<OrganizationFoodCreateUpdate>(
      {url: `/app/organization_foods/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: organizationFoodCreateUpdate
    },
      options);
    }
  


export const getAppOrganizationFoodsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsCreate>>, TError,{data: OrganizationFoodCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsCreate>>, TError,{data: OrganizationFoodCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appOrganizationFoodsCreate>>, {data: OrganizationFoodCreateUpdate}> = (props) => {
          const {data} = props ?? {};

          return  appOrganizationFoodsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppOrganizationFoodsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsCreate>>>
    export type AppOrganizationFoodsCreateMutationBody = OrganizationFoodCreateUpdate
    export type AppOrganizationFoodsCreateMutationError = unknown

    export const useAppOrganizationFoodsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsCreate>>, TError,{data: OrganizationFoodCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appOrganizationFoodsCreate>>,
        TError,
        {data: OrganizationFoodCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppOrganizationFoodsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appOrganizationFoodsRetrieve = (
    organizationFoodUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<OrganizationFoodList>(
      {url: `/app/organization_foods/${organizationFoodUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppOrganizationFoodsRetrieveQueryKey = (organizationFoodUuid: string,) => {
    return [`/app/organization_foods/${organizationFoodUuid}/`] as const;
    }

    
export const getAppOrganizationFoodsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError = unknown>(organizationFoodUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppOrganizationFoodsRetrieveQueryKey(organizationFoodUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>> = ({ signal }) => appOrganizationFoodsRetrieve(organizationFoodUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationFoodUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppOrganizationFoodsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>>
export type AppOrganizationFoodsRetrieveInfiniteQueryError = unknown

export const useAppOrganizationFoodsRetrieveInfinite = <TData = Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError = unknown>(
 organizationFoodUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppOrganizationFoodsRetrieveInfiniteQueryOptions(organizationFoodUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppOrganizationFoodsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError = unknown>(organizationFoodUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppOrganizationFoodsRetrieveQueryKey(organizationFoodUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>> = ({ signal }) => appOrganizationFoodsRetrieve(organizationFoodUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationFoodUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppOrganizationFoodsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>>
export type AppOrganizationFoodsRetrieveQueryError = unknown

export const useAppOrganizationFoodsRetrieve = <TData = Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError = unknown>(
 organizationFoodUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appOrganizationFoodsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppOrganizationFoodsRetrieveQueryOptions(organizationFoodUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appOrganizationFoodsUpdate = (
    organizationFoodUuid: string,
    organizationFoodCreateUpdate: OrganizationFoodCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<OrganizationFoodCreateUpdate>(
      {url: `/app/organization_foods/${organizationFoodUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: organizationFoodCreateUpdate
    },
      options);
    }
  


export const getAppOrganizationFoodsUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsUpdate>>, TError,{organizationFoodUuid: string;data: OrganizationFoodCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsUpdate>>, TError,{organizationFoodUuid: string;data: OrganizationFoodCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appOrganizationFoodsUpdate>>, {organizationFoodUuid: string;data: OrganizationFoodCreateUpdate}> = (props) => {
          const {organizationFoodUuid,data} = props ?? {};

          return  appOrganizationFoodsUpdate(organizationFoodUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppOrganizationFoodsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsUpdate>>>
    export type AppOrganizationFoodsUpdateMutationBody = OrganizationFoodCreateUpdate
    export type AppOrganizationFoodsUpdateMutationError = unknown

    export const useAppOrganizationFoodsUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsUpdate>>, TError,{organizationFoodUuid: string;data: OrganizationFoodCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appOrganizationFoodsUpdate>>,
        TError,
        {organizationFoodUuid: string;data: OrganizationFoodCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppOrganizationFoodsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appOrganizationFoodsPartialUpdate = (
    organizationFoodUuid: string,
    patchedOrganizationFoodCreateUpdate: PatchedOrganizationFoodCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<OrganizationFoodCreateUpdate>(
      {url: `/app/organization_foods/${organizationFoodUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedOrganizationFoodCreateUpdate
    },
      options);
    }
  


export const getAppOrganizationFoodsPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsPartialUpdate>>, TError,{organizationFoodUuid: string;data: PatchedOrganizationFoodCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsPartialUpdate>>, TError,{organizationFoodUuid: string;data: PatchedOrganizationFoodCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appOrganizationFoodsPartialUpdate>>, {organizationFoodUuid: string;data: PatchedOrganizationFoodCreateUpdate}> = (props) => {
          const {organizationFoodUuid,data} = props ?? {};

          return  appOrganizationFoodsPartialUpdate(organizationFoodUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppOrganizationFoodsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsPartialUpdate>>>
    export type AppOrganizationFoodsPartialUpdateMutationBody = PatchedOrganizationFoodCreateUpdate
    export type AppOrganizationFoodsPartialUpdateMutationError = unknown

    export const useAppOrganizationFoodsPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsPartialUpdate>>, TError,{organizationFoodUuid: string;data: PatchedOrganizationFoodCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appOrganizationFoodsPartialUpdate>>,
        TError,
        {organizationFoodUuid: string;data: PatchedOrganizationFoodCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppOrganizationFoodsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appOrganizationFoodsDestroy = (
    organizationFoodUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/organization_foods/${organizationFoodUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppOrganizationFoodsDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsDestroy>>, TError,{organizationFoodUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsDestroy>>, TError,{organizationFoodUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appOrganizationFoodsDestroy>>, {organizationFoodUuid: string}> = (props) => {
          const {organizationFoodUuid} = props ?? {};

          return  appOrganizationFoodsDestroy(organizationFoodUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppOrganizationFoodsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appOrganizationFoodsDestroy>>>
    
    export type AppOrganizationFoodsDestroyMutationError = unknown

    export const useAppOrganizationFoodsDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appOrganizationFoodsDestroy>>, TError,{organizationFoodUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appOrganizationFoodsDestroy>>,
        TError,
        {organizationFoodUuid: string},
        TContext
      > => {

      const mutationOptions = getAppOrganizationFoodsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appPackagingRetrieve = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<void>(
      {url: `/app/packaging/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppPackagingRetrieveQueryKey = () => {
    return [`/app/packaging/`] as const;
    }

    
export const getAppPackagingRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appPackagingRetrieve>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppPackagingRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appPackagingRetrieve>>> = ({ signal }) => appPackagingRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppPackagingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appPackagingRetrieve>>>
export type AppPackagingRetrieveInfiniteQueryError = unknown

export const useAppPackagingRetrieveInfinite = <TData = Awaited<ReturnType<typeof appPackagingRetrieve>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppPackagingRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppPackagingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appPackagingRetrieve>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppPackagingRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appPackagingRetrieve>>> = ({ signal }) => appPackagingRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppPackagingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appPackagingRetrieve>>>
export type AppPackagingRetrieveQueryError = unknown

export const useAppPackagingRetrieve = <TData = Awaited<ReturnType<typeof appPackagingRetrieve>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppPackagingRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appPackagingCreate = (
    packagingTypeCreate: NonReadonly<PackagingTypeCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<PackagingTypeCreate>(
      {url: `/app/packaging/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: packagingTypeCreate
    },
      options);
    }
  


export const getAppPackagingCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPackagingCreate>>, TError,{data: NonReadonly<PackagingTypeCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appPackagingCreate>>, TError,{data: NonReadonly<PackagingTypeCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appPackagingCreate>>, {data: NonReadonly<PackagingTypeCreate>}> = (props) => {
          const {data} = props ?? {};

          return  appPackagingCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppPackagingCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appPackagingCreate>>>
    export type AppPackagingCreateMutationBody = NonReadonly<PackagingTypeCreate>
    export type AppPackagingCreateMutationError = unknown

    export const useAppPackagingCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPackagingCreate>>, TError,{data: NonReadonly<PackagingTypeCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appPackagingCreate>>,
        TError,
        {data: NonReadonly<PackagingTypeCreate>},
        TContext
      > => {

      const mutationOptions = getAppPackagingCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appPackagingRetrieve2 = (
    packagingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<void>(
      {url: `/app/packaging/${packagingTypeUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppPackagingRetrieve2QueryKey = (packagingTypeUuid: string,) => {
    return [`/app/packaging/${packagingTypeUuid}/`] as const;
    }

    
export const getAppPackagingRetrieve2InfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError = unknown>(packagingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppPackagingRetrieve2QueryKey(packagingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appPackagingRetrieve2>>> = ({ signal }) => appPackagingRetrieve2(packagingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(packagingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError, TData> & { queryKey: QueryKey }
}

export type AppPackagingRetrieve2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appPackagingRetrieve2>>>
export type AppPackagingRetrieve2InfiniteQueryError = unknown

export const useAppPackagingRetrieve2Infinite = <TData = Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError = unknown>(
 packagingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppPackagingRetrieve2InfiniteQueryOptions(packagingTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppPackagingRetrieve2QueryOptions = <TData = Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError = unknown>(packagingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppPackagingRetrieve2QueryKey(packagingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appPackagingRetrieve2>>> = ({ signal }) => appPackagingRetrieve2(packagingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(packagingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError, TData> & { queryKey: QueryKey }
}

export type AppPackagingRetrieve2QueryResult = NonNullable<Awaited<ReturnType<typeof appPackagingRetrieve2>>>
export type AppPackagingRetrieve2QueryError = unknown

export const useAppPackagingRetrieve2 = <TData = Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError = unknown>(
 packagingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appPackagingRetrieve2>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppPackagingRetrieve2QueryOptions(packagingTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Should handle the update of the packaging type description
 */
export const appPackagingUpdate = (
    packagingTypeUuid: string,
    packagingTypeUpdate: NonReadonly<PackagingTypeUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<PackagingTypeUpdate>(
      {url: `/app/packaging/${packagingTypeUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: packagingTypeUpdate
    },
      options);
    }
  


export const getAppPackagingUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPackagingUpdate>>, TError,{packagingTypeUuid: string;data: NonReadonly<PackagingTypeUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appPackagingUpdate>>, TError,{packagingTypeUuid: string;data: NonReadonly<PackagingTypeUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appPackagingUpdate>>, {packagingTypeUuid: string;data: NonReadonly<PackagingTypeUpdate>}> = (props) => {
          const {packagingTypeUuid,data} = props ?? {};

          return  appPackagingUpdate(packagingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppPackagingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appPackagingUpdate>>>
    export type AppPackagingUpdateMutationBody = NonReadonly<PackagingTypeUpdate>
    export type AppPackagingUpdateMutationError = unknown

    export const useAppPackagingUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPackagingUpdate>>, TError,{packagingTypeUuid: string;data: NonReadonly<PackagingTypeUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appPackagingUpdate>>,
        TError,
        {packagingTypeUuid: string;data: NonReadonly<PackagingTypeUpdate>},
        TContext
      > => {

      const mutationOptions = getAppPackagingUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appPackagingFileRetrieve = (
    packagingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PackagingTypeFileRetrieve>(
      {url: `/app/packaging/${packagingTypeUuid}/file/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppPackagingFileRetrieveQueryKey = (packagingTypeUuid: string,) => {
    return [`/app/packaging/${packagingTypeUuid}/file/`] as const;
    }

    
export const getAppPackagingFileRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError = unknown>(packagingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppPackagingFileRetrieveQueryKey(packagingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appPackagingFileRetrieve>>> = ({ signal }) => appPackagingFileRetrieve(packagingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(packagingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppPackagingFileRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appPackagingFileRetrieve>>>
export type AppPackagingFileRetrieveInfiniteQueryError = unknown

export const useAppPackagingFileRetrieveInfinite = <TData = Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError = unknown>(
 packagingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppPackagingFileRetrieveInfiniteQueryOptions(packagingTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppPackagingFileRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError = unknown>(packagingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppPackagingFileRetrieveQueryKey(packagingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appPackagingFileRetrieve>>> = ({ signal }) => appPackagingFileRetrieve(packagingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(packagingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppPackagingFileRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appPackagingFileRetrieve>>>
export type AppPackagingFileRetrieveQueryError = unknown

export const useAppPackagingFileRetrieve = <TData = Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError = unknown>(
 packagingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appPackagingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppPackagingFileRetrieveQueryOptions(packagingTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appPackagingFileCreate = (
    packagingTypeUuid: string,
    packagingTypeFileUuid: string,
    packagingTypeFileFinish: PackagingTypeFileFinish,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<PackagingTypeFileFinish>(
      {url: `/app/packaging/${packagingTypeUuid}/file/${packagingTypeFileUuid}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: packagingTypeFileFinish
    },
      options);
    }
  


export const getAppPackagingFileCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPackagingFileCreate>>, TError,{packagingTypeUuid: string;packagingTypeFileUuid: string;data: PackagingTypeFileFinish}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appPackagingFileCreate>>, TError,{packagingTypeUuid: string;packagingTypeFileUuid: string;data: PackagingTypeFileFinish}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appPackagingFileCreate>>, {packagingTypeUuid: string;packagingTypeFileUuid: string;data: PackagingTypeFileFinish}> = (props) => {
          const {packagingTypeUuid,packagingTypeFileUuid,data} = props ?? {};

          return  appPackagingFileCreate(packagingTypeUuid,packagingTypeFileUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppPackagingFileCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appPackagingFileCreate>>>
    export type AppPackagingFileCreateMutationBody = PackagingTypeFileFinish
    export type AppPackagingFileCreateMutationError = unknown

    export const useAppPackagingFileCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPackagingFileCreate>>, TError,{packagingTypeUuid: string;packagingTypeFileUuid: string;data: PackagingTypeFileFinish}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appPackagingFileCreate>>,
        TError,
        {packagingTypeUuid: string;packagingTypeFileUuid: string;data: PackagingTypeFileFinish},
        TContext
      > => {

      const mutationOptions = getAppPackagingFileCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Calls Django Auth SetPasswordForm save method.

Accepts the following POST parameters: new_password1, new_password2
Returns the success/fail message.
 */
export const appPasswordChangeCreate = (
    passwordChange: PasswordChange,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/password/change/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: passwordChange
    },
      options);
    }
  


export const getAppPasswordChangeCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPasswordChangeCreate>>, TError,{data: PasswordChange}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appPasswordChangeCreate>>, TError,{data: PasswordChange}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appPasswordChangeCreate>>, {data: PasswordChange}> = (props) => {
          const {data} = props ?? {};

          return  appPasswordChangeCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppPasswordChangeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appPasswordChangeCreate>>>
    export type AppPasswordChangeCreateMutationBody = PasswordChange
    export type AppPasswordChangeCreateMutationError = unknown

    export const useAppPasswordChangeCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPasswordChangeCreate>>, TError,{data: PasswordChange}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appPasswordChangeCreate>>,
        TError,
        {data: PasswordChange},
        TContext
      > => {

      const mutationOptions = getAppPasswordChangeCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Calls Django Auth PasswordResetForm save method.

Accepts the following POST parameters: email
Returns the success/fail message.
 */
export const appPasswordResetCreate = (
    myPasswordReset: MyPasswordReset,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/password/reset/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: myPasswordReset
    },
      options);
    }
  


export const getAppPasswordResetCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPasswordResetCreate>>, TError,{data: MyPasswordReset}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appPasswordResetCreate>>, TError,{data: MyPasswordReset}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appPasswordResetCreate>>, {data: MyPasswordReset}> = (props) => {
          const {data} = props ?? {};

          return  appPasswordResetCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppPasswordResetCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appPasswordResetCreate>>>
    export type AppPasswordResetCreateMutationBody = MyPasswordReset
    export type AppPasswordResetCreateMutationError = unknown

    export const useAppPasswordResetCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPasswordResetCreate>>, TError,{data: MyPasswordReset}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appPasswordResetCreate>>,
        TError,
        {data: MyPasswordReset},
        TContext
      > => {

      const mutationOptions = getAppPasswordResetCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Password reset e-mail link is confirmed, therefore
this resets the user's password.

Accepts the following POST parameters: token, uid,
    new_password1, new_password2
Returns the success/fail message.
 */
export const appPasswordResetConfirmCreate = (
    uidb64: string,
    token: string,
    passwordResetConfirm: PasswordResetConfirm,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/password/reset/confirm/${uidb64}/${token}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: passwordResetConfirm
    },
      options);
    }
  


export const getAppPasswordResetConfirmCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPasswordResetConfirmCreate>>, TError,{uidb64: string;token: string;data: PasswordResetConfirm}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appPasswordResetConfirmCreate>>, TError,{uidb64: string;token: string;data: PasswordResetConfirm}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appPasswordResetConfirmCreate>>, {uidb64: string;token: string;data: PasswordResetConfirm}> = (props) => {
          const {uidb64,token,data} = props ?? {};

          return  appPasswordResetConfirmCreate(uidb64,token,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppPasswordResetConfirmCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appPasswordResetConfirmCreate>>>
    export type AppPasswordResetConfirmCreateMutationBody = PasswordResetConfirm
    export type AppPasswordResetConfirmCreateMutationError = unknown

    export const useAppPasswordResetConfirmCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appPasswordResetConfirmCreate>>, TError,{uidb64: string;token: string;data: PasswordResetConfirm}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appPasswordResetConfirmCreate>>,
        TError,
        {uidb64: string;token: string;data: PasswordResetConfirm},
        TContext
      > => {

      const mutationOptions = getAppPasswordResetConfirmCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProcessingList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProcessingTypeList[]>(
      {url: `/app/processing/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProcessingListQueryKey = () => {
    return [`/app/processing/`] as const;
    }

    
export const getAppProcessingListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProcessingList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProcessingListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProcessingList>>> = ({ signal }) => appProcessingList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProcessingListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProcessingList>>>
export type AppProcessingListInfiniteQueryError = unknown

export const useAppProcessingListInfinite = <TData = Awaited<ReturnType<typeof appProcessingList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProcessingListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProcessingListQueryOptions = <TData = Awaited<ReturnType<typeof appProcessingList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProcessingListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProcessingList>>> = ({ signal }) => appProcessingList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProcessingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProcessingListQueryResult = NonNullable<Awaited<ReturnType<typeof appProcessingList>>>
export type AppProcessingListQueryError = unknown

export const useAppProcessingList = <TData = Awaited<ReturnType<typeof appProcessingList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProcessingListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProcessingCreate = (
    processingTypeCreate: NonReadonly<ProcessingTypeCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProcessingTypeCreate>(
      {url: `/app/processing/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: processingTypeCreate
    },
      options);
    }
  


export const getAppProcessingCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingCreate>>, TError,{data: NonReadonly<ProcessingTypeCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProcessingCreate>>, TError,{data: NonReadonly<ProcessingTypeCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProcessingCreate>>, {data: NonReadonly<ProcessingTypeCreate>}> = (props) => {
          const {data} = props ?? {};

          return  appProcessingCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProcessingCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProcessingCreate>>>
    export type AppProcessingCreateMutationBody = NonReadonly<ProcessingTypeCreate>
    export type AppProcessingCreateMutationError = unknown

    export const useAppProcessingCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingCreate>>, TError,{data: NonReadonly<ProcessingTypeCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProcessingCreate>>,
        TError,
        {data: NonReadonly<ProcessingTypeCreate>},
        TContext
      > => {

      const mutationOptions = getAppProcessingCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProcessingRetrieve = (
    processingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProcessingTypeList>(
      {url: `/app/processing/${processingTypeUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProcessingRetrieveQueryKey = (processingTypeUuid: string,) => {
    return [`/app/processing/${processingTypeUuid}/`] as const;
    }

    
export const getAppProcessingRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProcessingRetrieve>>, TError = unknown>(processingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProcessingRetrieveQueryKey(processingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProcessingRetrieve>>> = ({ signal }) => appProcessingRetrieve(processingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(processingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProcessingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProcessingRetrieve>>>
export type AppProcessingRetrieveInfiniteQueryError = unknown

export const useAppProcessingRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProcessingRetrieve>>, TError = unknown>(
 processingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProcessingRetrieveInfiniteQueryOptions(processingTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProcessingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProcessingRetrieve>>, TError = unknown>(processingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProcessingRetrieveQueryKey(processingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProcessingRetrieve>>> = ({ signal }) => appProcessingRetrieve(processingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(processingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProcessingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProcessingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProcessingRetrieve>>>
export type AppProcessingRetrieveQueryError = unknown

export const useAppProcessingRetrieve = <TData = Awaited<ReturnType<typeof appProcessingRetrieve>>, TError = unknown>(
 processingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProcessingRetrieveQueryOptions(processingTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProcessingUpdate = (
    processingTypeUuid: string,
    processingTypeUpdate: NonReadonly<ProcessingTypeUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProcessingTypeUpdate>(
      {url: `/app/processing/${processingTypeUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: processingTypeUpdate
    },
      options);
    }
  


export const getAppProcessingUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingUpdate>>, TError,{processingTypeUuid: string;data: NonReadonly<ProcessingTypeUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProcessingUpdate>>, TError,{processingTypeUuid: string;data: NonReadonly<ProcessingTypeUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProcessingUpdate>>, {processingTypeUuid: string;data: NonReadonly<ProcessingTypeUpdate>}> = (props) => {
          const {processingTypeUuid,data} = props ?? {};

          return  appProcessingUpdate(processingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProcessingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProcessingUpdate>>>
    export type AppProcessingUpdateMutationBody = NonReadonly<ProcessingTypeUpdate>
    export type AppProcessingUpdateMutationError = unknown

    export const useAppProcessingUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingUpdate>>, TError,{processingTypeUuid: string;data: NonReadonly<ProcessingTypeUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProcessingUpdate>>,
        TError,
        {processingTypeUuid: string;data: NonReadonly<ProcessingTypeUpdate>},
        TContext
      > => {

      const mutationOptions = getAppProcessingUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProcessingPartialUpdate = (
    processingTypeUuid: string,
    patchedProcessingTypeUpdate: NonReadonly<PatchedProcessingTypeUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProcessingTypeUpdate>(
      {url: `/app/processing/${processingTypeUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedProcessingTypeUpdate
    },
      options);
    }
  


export const getAppProcessingPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingPartialUpdate>>, TError,{processingTypeUuid: string;data: NonReadonly<PatchedProcessingTypeUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProcessingPartialUpdate>>, TError,{processingTypeUuid: string;data: NonReadonly<PatchedProcessingTypeUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProcessingPartialUpdate>>, {processingTypeUuid: string;data: NonReadonly<PatchedProcessingTypeUpdate>}> = (props) => {
          const {processingTypeUuid,data} = props ?? {};

          return  appProcessingPartialUpdate(processingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProcessingPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProcessingPartialUpdate>>>
    export type AppProcessingPartialUpdateMutationBody = NonReadonly<PatchedProcessingTypeUpdate>
    export type AppProcessingPartialUpdateMutationError = unknown

    export const useAppProcessingPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingPartialUpdate>>, TError,{processingTypeUuid: string;data: NonReadonly<PatchedProcessingTypeUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProcessingPartialUpdate>>,
        TError,
        {processingTypeUuid: string;data: NonReadonly<PatchedProcessingTypeUpdate>},
        TContext
      > => {

      const mutationOptions = getAppProcessingPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProcessingDestroy = (
    processingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/processing/${processingTypeUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppProcessingDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingDestroy>>, TError,{processingTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProcessingDestroy>>, TError,{processingTypeUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProcessingDestroy>>, {processingTypeUuid: string}> = (props) => {
          const {processingTypeUuid} = props ?? {};

          return  appProcessingDestroy(processingTypeUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProcessingDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appProcessingDestroy>>>
    
    export type AppProcessingDestroyMutationError = unknown

    export const useAppProcessingDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingDestroy>>, TError,{processingTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProcessingDestroy>>,
        TError,
        {processingTypeUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProcessingDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProcessingFileRetrieve = (
    processingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProcessingTypeFileRetrieve>(
      {url: `/app/processing/${processingTypeUuid}/file/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProcessingFileRetrieveQueryKey = (processingTypeUuid: string,) => {
    return [`/app/processing/${processingTypeUuid}/file/`] as const;
    }

    
export const getAppProcessingFileRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError = unknown>(processingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProcessingFileRetrieveQueryKey(processingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProcessingFileRetrieve>>> = ({ signal }) => appProcessingFileRetrieve(processingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(processingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProcessingFileRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProcessingFileRetrieve>>>
export type AppProcessingFileRetrieveInfiniteQueryError = unknown

export const useAppProcessingFileRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError = unknown>(
 processingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProcessingFileRetrieveInfiniteQueryOptions(processingTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProcessingFileRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError = unknown>(processingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProcessingFileRetrieveQueryKey(processingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProcessingFileRetrieve>>> = ({ signal }) => appProcessingFileRetrieve(processingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(processingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProcessingFileRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProcessingFileRetrieve>>>
export type AppProcessingFileRetrieveQueryError = unknown

export const useAppProcessingFileRetrieve = <TData = Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError = unknown>(
 processingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProcessingFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProcessingFileRetrieveQueryOptions(processingTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProcessingFileCreate = (
    processingTypeUuid: string,
    processingTypeFileUuid: string,
    processingTypeFileFinish: ProcessingTypeFileFinish,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProcessingTypeFileFinish>(
      {url: `/app/processing/${processingTypeUuid}/file/${processingTypeFileUuid}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: processingTypeFileFinish
    },
      options);
    }
  


export const getAppProcessingFileCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingFileCreate>>, TError,{processingTypeUuid: string;processingTypeFileUuid: string;data: ProcessingTypeFileFinish}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProcessingFileCreate>>, TError,{processingTypeUuid: string;processingTypeFileUuid: string;data: ProcessingTypeFileFinish}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProcessingFileCreate>>, {processingTypeUuid: string;processingTypeFileUuid: string;data: ProcessingTypeFileFinish}> = (props) => {
          const {processingTypeUuid,processingTypeFileUuid,data} = props ?? {};

          return  appProcessingFileCreate(processingTypeUuid,processingTypeFileUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProcessingFileCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProcessingFileCreate>>>
    export type AppProcessingFileCreateMutationBody = ProcessingTypeFileFinish
    export type AppProcessingFileCreateMutationError = unknown

    export const useAppProcessingFileCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProcessingFileCreate>>, TError,{processingTypeUuid: string;processingTypeFileUuid: string;data: ProcessingTypeFileFinish}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProcessingFileCreate>>,
        TError,
        {processingTypeUuid: string;processingTypeFileUuid: string;data: ProcessingTypeFileFinish},
        TContext
      > => {

      const mutationOptions = getAppProcessingFileCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsList = (
    params?: AppProductsListParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<PaginatedProductListList>(
      {url: `/app/products/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppProductsListQueryKey = (params?: AppProductsListParams,) => {
    return [`/app/products/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppProductsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsList>>, TError = unknown>(params?: AppProductsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsList>>> = ({ signal, pageParam }) => appProductsList({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsList>>>
export type AppProductsListInfiniteQueryError = unknown

export const useAppProductsListInfinite = <TData = Awaited<ReturnType<typeof appProductsList>>, TError = unknown>(
 params?: AppProductsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsListQueryOptions = <TData = Awaited<ReturnType<typeof appProductsList>>, TError = unknown>(params?: AppProductsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsList>>> = ({ signal }) => appProductsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsListQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsList>>>
export type AppProductsListQueryError = unknown

export const useAppProductsList = <TData = Awaited<ReturnType<typeof appProductsList>>, TError = unknown>(
 params?: AppProductsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsCreate = (
    productCreateUpdate: ProductCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductCreateUpdate>(
      {url: `/app/products/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productCreateUpdate
    },
      options);
    }
  


export const getAppProductsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsCreate>>, TError,{data: ProductCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsCreate>>, TError,{data: ProductCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsCreate>>, {data: ProductCreateUpdate}> = (props) => {
          const {data} = props ?? {};

          return  appProductsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsCreate>>>
    export type AppProductsCreateMutationBody = ProductCreateUpdate
    export type AppProductsCreateMutationError = unknown

    export const useAppProductsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsCreate>>, TError,{data: ProductCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsCreate>>,
        TError,
        {data: ProductCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsRetrieve = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductDetail>(
      {url: `/app/products/${productUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsRetrieveQueryKey = (productUuid: string,) => {
    return [`/app/products/${productUuid}/`] as const;
    }

    
export const getAppProductsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsRetrieve>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsRetrieveQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsRetrieve>>> = ({ signal }) => appProductsRetrieve(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsRetrieve>>>
export type AppProductsRetrieveInfiniteQueryError = unknown

export const useAppProductsRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsRetrieve>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsRetrieveInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsRetrieve>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsRetrieveQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsRetrieve>>> = ({ signal }) => appProductsRetrieve(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsRetrieve>>>
export type AppProductsRetrieveQueryError = unknown

export const useAppProductsRetrieve = <TData = Awaited<ReturnType<typeof appProductsRetrieve>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsRetrieveQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsUpdate = (
    productUuid: string,
    productCreateUpdate: ProductCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductCreateUpdate>(
      {url: `/app/products/${productUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productCreateUpdate
    },
      options);
    }
  


export const getAppProductsUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsUpdate>>, TError,{productUuid: string;data: ProductCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsUpdate>>, TError,{productUuid: string;data: ProductCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsUpdate>>, {productUuid: string;data: ProductCreateUpdate}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  appProductsUpdate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsUpdate>>>
    export type AppProductsUpdateMutationBody = ProductCreateUpdate
    export type AppProductsUpdateMutationError = unknown

    export const useAppProductsUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsUpdate>>, TError,{productUuid: string;data: ProductCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsUpdate>>,
        TError,
        {productUuid: string;data: ProductCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsPartialUpdate = (
    productUuid: string,
    patchedProductCreateUpdate: PatchedProductCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductCreateUpdate>(
      {url: `/app/products/${productUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedProductCreateUpdate
    },
      options);
    }
  


export const getAppProductsPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPartialUpdate>>, TError,{productUuid: string;data: PatchedProductCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsPartialUpdate>>, TError,{productUuid: string;data: PatchedProductCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsPartialUpdate>>, {productUuid: string;data: PatchedProductCreateUpdate}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  appProductsPartialUpdate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsPartialUpdate>>>
    export type AppProductsPartialUpdateMutationBody = PatchedProductCreateUpdate
    export type AppProductsPartialUpdateMutationError = unknown

    export const useAppProductsPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPartialUpdate>>, TError,{productUuid: string;data: PatchedProductCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsPartialUpdate>>,
        TError,
        {productUuid: string;data: PatchedProductCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsDestroy = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppProductsDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsDestroy>>, TError,{productUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsDestroy>>, TError,{productUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsDestroy>>, {productUuid: string}> = (props) => {
          const {productUuid} = props ?? {};

          return  appProductsDestroy(productUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsDestroy>>>
    
    export type AppProductsDestroyMutationError = unknown

    export const useAppProductsDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsDestroy>>, TError,{productUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsDestroy>>,
        TError,
        {productUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View which accepts a post request to move a product back to editable status (Draft)

All that happens at the moment is that the status is moved to Draft
 */
export const appProductsEditCreate = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/edit/`, method: 'POST'
    },
      options);
    }
  


export const getAppProductsEditCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsEditCreate>>, TError,{productUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsEditCreate>>, TError,{productUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsEditCreate>>, {productUuid: string}> = (props) => {
          const {productUuid} = props ?? {};

          return  appProductsEditCreate(productUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsEditCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsEditCreate>>>
    
    export type AppProductsEditCreateMutationError = unknown

    export const useAppProductsEditCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsEditCreate>>, TError,{productUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsEditCreate>>,
        TError,
        {productUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsEditCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsIngredientsList = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<IngredientList[]>(
      {url: `/app/products/${productUuid}/ingredients/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsIngredientsListQueryKey = (productUuid: string,) => {
    return [`/app/products/${productUuid}/ingredients/`] as const;
    }

    
export const getAppProductsIngredientsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsIngredientsList>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsIngredientsListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsIngredientsList>>> = ({ signal }) => appProductsIngredientsList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsIngredientsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsList>>>
export type AppProductsIngredientsListInfiniteQueryError = unknown

export const useAppProductsIngredientsListInfinite = <TData = Awaited<ReturnType<typeof appProductsIngredientsList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsIngredientsListInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsIngredientsListQueryOptions = <TData = Awaited<ReturnType<typeof appProductsIngredientsList>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsIngredientsListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsIngredientsList>>> = ({ signal }) => appProductsIngredientsList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsIngredientsListQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsList>>>
export type AppProductsIngredientsListQueryError = unknown

export const useAppProductsIngredientsList = <TData = Awaited<ReturnType<typeof appProductsIngredientsList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsIngredientsListQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsIngredientsCreate = (
    productUuid: string,
    ingredientCreateUpdate: IngredientCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<IngredientCreateUpdate>(
      {url: `/app/products/${productUuid}/ingredients/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: ingredientCreateUpdate
    },
      options);
    }
  


export const getAppProductsIngredientsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsCreate>>, TError,{productUuid: string;data: IngredientCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsCreate>>, TError,{productUuid: string;data: IngredientCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsIngredientsCreate>>, {productUuid: string;data: IngredientCreateUpdate}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  appProductsIngredientsCreate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsIngredientsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsCreate>>>
    export type AppProductsIngredientsCreateMutationBody = IngredientCreateUpdate
    export type AppProductsIngredientsCreateMutationError = unknown

    export const useAppProductsIngredientsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsCreate>>, TError,{productUuid: string;data: IngredientCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsIngredientsCreate>>,
        TError,
        {productUuid: string;data: IngredientCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsIngredientsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsIngredientsRetrieve = (
    productUuid: string,
    ingredientUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<IngredientList>(
      {url: `/app/products/${productUuid}/ingredients/${ingredientUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsIngredientsRetrieveQueryKey = (productUuid: string,
    ingredientUuid: string,) => {
    return [`/app/products/${productUuid}/ingredients/${ingredientUuid}/`] as const;
    }

    
export const getAppProductsIngredientsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError = unknown>(productUuid: string,
    ingredientUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsIngredientsRetrieveQueryKey(productUuid,ingredientUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>> = ({ signal }) => appProductsIngredientsRetrieve(productUuid,ingredientUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && ingredientUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsIngredientsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>>
export type AppProductsIngredientsRetrieveInfiniteQueryError = unknown

export const useAppProductsIngredientsRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError = unknown>(
 productUuid: string,
    ingredientUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsIngredientsRetrieveInfiniteQueryOptions(productUuid,ingredientUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsIngredientsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError = unknown>(productUuid: string,
    ingredientUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsIngredientsRetrieveQueryKey(productUuid,ingredientUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>> = ({ signal }) => appProductsIngredientsRetrieve(productUuid,ingredientUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && ingredientUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsIngredientsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>>
export type AppProductsIngredientsRetrieveQueryError = unknown

export const useAppProductsIngredientsRetrieve = <TData = Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError = unknown>(
 productUuid: string,
    ingredientUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsIngredientsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsIngredientsRetrieveQueryOptions(productUuid,ingredientUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsIngredientsUpdate = (
    productUuid: string,
    ingredientUuid: string,
    ingredientCreateUpdate: IngredientCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<IngredientCreateUpdate>(
      {url: `/app/products/${productUuid}/ingredients/${ingredientUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: ingredientCreateUpdate
    },
      options);
    }
  


export const getAppProductsIngredientsUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsUpdate>>, TError,{productUuid: string;ingredientUuid: string;data: IngredientCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsUpdate>>, TError,{productUuid: string;ingredientUuid: string;data: IngredientCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsIngredientsUpdate>>, {productUuid: string;ingredientUuid: string;data: IngredientCreateUpdate}> = (props) => {
          const {productUuid,ingredientUuid,data} = props ?? {};

          return  appProductsIngredientsUpdate(productUuid,ingredientUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsIngredientsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsUpdate>>>
    export type AppProductsIngredientsUpdateMutationBody = IngredientCreateUpdate
    export type AppProductsIngredientsUpdateMutationError = unknown

    export const useAppProductsIngredientsUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsUpdate>>, TError,{productUuid: string;ingredientUuid: string;data: IngredientCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsIngredientsUpdate>>,
        TError,
        {productUuid: string;ingredientUuid: string;data: IngredientCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsIngredientsUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsIngredientsPartialUpdate = (
    productUuid: string,
    ingredientUuid: string,
    patchedIngredientCreateUpdate: PatchedIngredientCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<IngredientCreateUpdate>(
      {url: `/app/products/${productUuid}/ingredients/${ingredientUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedIngredientCreateUpdate
    },
      options);
    }
  


export const getAppProductsIngredientsPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsPartialUpdate>>, TError,{productUuid: string;ingredientUuid: string;data: PatchedIngredientCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsPartialUpdate>>, TError,{productUuid: string;ingredientUuid: string;data: PatchedIngredientCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsIngredientsPartialUpdate>>, {productUuid: string;ingredientUuid: string;data: PatchedIngredientCreateUpdate}> = (props) => {
          const {productUuid,ingredientUuid,data} = props ?? {};

          return  appProductsIngredientsPartialUpdate(productUuid,ingredientUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsIngredientsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsPartialUpdate>>>
    export type AppProductsIngredientsPartialUpdateMutationBody = PatchedIngredientCreateUpdate
    export type AppProductsIngredientsPartialUpdateMutationError = unknown

    export const useAppProductsIngredientsPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsPartialUpdate>>, TError,{productUuid: string;ingredientUuid: string;data: PatchedIngredientCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsIngredientsPartialUpdate>>,
        TError,
        {productUuid: string;ingredientUuid: string;data: PatchedIngredientCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsIngredientsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsIngredientsDestroy = (
    productUuid: string,
    ingredientUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/ingredients/${ingredientUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppProductsIngredientsDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsDestroy>>, TError,{productUuid: string;ingredientUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsDestroy>>, TError,{productUuid: string;ingredientUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsIngredientsDestroy>>, {productUuid: string;ingredientUuid: string}> = (props) => {
          const {productUuid,ingredientUuid} = props ?? {};

          return  appProductsIngredientsDestroy(productUuid,ingredientUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsIngredientsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsIngredientsDestroy>>>
    
    export type AppProductsIngredientsDestroyMutationError = unknown

    export const useAppProductsIngredientsDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsIngredientsDestroy>>, TError,{productUuid: string;ingredientUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsIngredientsDestroy>>,
        TError,
        {productUuid: string;ingredientUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsIngredientsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsPackagingList = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductPackagingTypeList[]>(
      {url: `/app/products/${productUuid}/packaging/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsPackagingListQueryKey = (productUuid: string,) => {
    return [`/app/products/${productUuid}/packaging/`] as const;
    }

    
export const getAppProductsPackagingListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsPackagingList>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsPackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsPackagingListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsPackagingList>>> = ({ signal }) => appProductsPackagingList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsPackagingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsPackagingListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingList>>>
export type AppProductsPackagingListInfiniteQueryError = unknown

export const useAppProductsPackagingListInfinite = <TData = Awaited<ReturnType<typeof appProductsPackagingList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsPackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsPackagingListInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsPackagingListQueryOptions = <TData = Awaited<ReturnType<typeof appProductsPackagingList>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsPackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsPackagingListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsPackagingList>>> = ({ signal }) => appProductsPackagingList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsPackagingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsPackagingListQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingList>>>
export type AppProductsPackagingListQueryError = unknown

export const useAppProductsPackagingList = <TData = Awaited<ReturnType<typeof appProductsPackagingList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsPackagingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsPackagingListQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsPackagingCreate = (
    productUuid: string,
    productPackagingTypeCreateUpdate: ProductPackagingTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductPackagingTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/packaging/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productPackagingTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsPackagingCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingCreate>>, TError,{productUuid: string;data: ProductPackagingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingCreate>>, TError,{productUuid: string;data: ProductPackagingTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsPackagingCreate>>, {productUuid: string;data: ProductPackagingTypeCreateUpdate}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  appProductsPackagingCreate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsPackagingCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingCreate>>>
    export type AppProductsPackagingCreateMutationBody = ProductPackagingTypeCreateUpdate
    export type AppProductsPackagingCreateMutationError = unknown

    export const useAppProductsPackagingCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingCreate>>, TError,{productUuid: string;data: ProductPackagingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsPackagingCreate>>,
        TError,
        {productUuid: string;data: ProductPackagingTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsPackagingCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsPackagingRetrieve = (
    productUuid: string,
    productPackagingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductPackagingTypeList>(
      {url: `/app/products/${productUuid}/packaging/${productPackagingTypeUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsPackagingRetrieveQueryKey = (productUuid: string,
    productPackagingTypeUuid: string,) => {
    return [`/app/products/${productUuid}/packaging/${productPackagingTypeUuid}/`] as const;
    }

    
export const getAppProductsPackagingRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError = unknown>(productUuid: string,
    productPackagingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsPackagingRetrieveQueryKey(productUuid,productPackagingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>> = ({ signal }) => appProductsPackagingRetrieve(productUuid,productPackagingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && productPackagingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsPackagingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>>
export type AppProductsPackagingRetrieveInfiniteQueryError = unknown

export const useAppProductsPackagingRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError = unknown>(
 productUuid: string,
    productPackagingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsPackagingRetrieveInfiniteQueryOptions(productUuid,productPackagingTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsPackagingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError = unknown>(productUuid: string,
    productPackagingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsPackagingRetrieveQueryKey(productUuid,productPackagingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>> = ({ signal }) => appProductsPackagingRetrieve(productUuid,productPackagingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && productPackagingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsPackagingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>>
export type AppProductsPackagingRetrieveQueryError = unknown

export const useAppProductsPackagingRetrieve = <TData = Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError = unknown>(
 productUuid: string,
    productPackagingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsPackagingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsPackagingRetrieveQueryOptions(productUuid,productPackagingTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsPackagingUpdate = (
    productUuid: string,
    productPackagingTypeUuid: string,
    productPackagingTypeCreateUpdate: ProductPackagingTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductPackagingTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/packaging/${productPackagingTypeUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productPackagingTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsPackagingUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingUpdate>>, TError,{productUuid: string;productPackagingTypeUuid: string;data: ProductPackagingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingUpdate>>, TError,{productUuid: string;productPackagingTypeUuid: string;data: ProductPackagingTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsPackagingUpdate>>, {productUuid: string;productPackagingTypeUuid: string;data: ProductPackagingTypeCreateUpdate}> = (props) => {
          const {productUuid,productPackagingTypeUuid,data} = props ?? {};

          return  appProductsPackagingUpdate(productUuid,productPackagingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsPackagingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingUpdate>>>
    export type AppProductsPackagingUpdateMutationBody = ProductPackagingTypeCreateUpdate
    export type AppProductsPackagingUpdateMutationError = unknown

    export const useAppProductsPackagingUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingUpdate>>, TError,{productUuid: string;productPackagingTypeUuid: string;data: ProductPackagingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsPackagingUpdate>>,
        TError,
        {productUuid: string;productPackagingTypeUuid: string;data: ProductPackagingTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsPackagingUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsPackagingPartialUpdate = (
    productUuid: string,
    productPackagingTypeUuid: string,
    patchedProductPackagingTypeCreateUpdate: PatchedProductPackagingTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductPackagingTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/packaging/${productPackagingTypeUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedProductPackagingTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsPackagingPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingPartialUpdate>>, TError,{productUuid: string;productPackagingTypeUuid: string;data: PatchedProductPackagingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingPartialUpdate>>, TError,{productUuid: string;productPackagingTypeUuid: string;data: PatchedProductPackagingTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsPackagingPartialUpdate>>, {productUuid: string;productPackagingTypeUuid: string;data: PatchedProductPackagingTypeCreateUpdate}> = (props) => {
          const {productUuid,productPackagingTypeUuid,data} = props ?? {};

          return  appProductsPackagingPartialUpdate(productUuid,productPackagingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsPackagingPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingPartialUpdate>>>
    export type AppProductsPackagingPartialUpdateMutationBody = PatchedProductPackagingTypeCreateUpdate
    export type AppProductsPackagingPartialUpdateMutationError = unknown

    export const useAppProductsPackagingPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingPartialUpdate>>, TError,{productUuid: string;productPackagingTypeUuid: string;data: PatchedProductPackagingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsPackagingPartialUpdate>>,
        TError,
        {productUuid: string;productPackagingTypeUuid: string;data: PatchedProductPackagingTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsPackagingPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsPackagingDestroy = (
    productUuid: string,
    productPackagingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/packaging/${productPackagingTypeUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppProductsPackagingDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingDestroy>>, TError,{productUuid: string;productPackagingTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingDestroy>>, TError,{productUuid: string;productPackagingTypeUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsPackagingDestroy>>, {productUuid: string;productPackagingTypeUuid: string}> = (props) => {
          const {productUuid,productPackagingTypeUuid} = props ?? {};

          return  appProductsPackagingDestroy(productUuid,productPackagingTypeUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsPackagingDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsPackagingDestroy>>>
    
    export type AppProductsPackagingDestroyMutationError = unknown

    export const useAppProductsPackagingDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsPackagingDestroy>>, TError,{productUuid: string;productPackagingTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsPackagingDestroy>>,
        TError,
        {productUuid: string;productPackagingTypeUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsPackagingDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsProcessingList = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductProcessingTypeList[]>(
      {url: `/app/products/${productUuid}/processing/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsProcessingListQueryKey = (productUuid: string,) => {
    return [`/app/products/${productUuid}/processing/`] as const;
    }

    
export const getAppProductsProcessingListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsProcessingList>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsProcessingListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsProcessingList>>> = ({ signal }) => appProductsProcessingList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsProcessingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsProcessingListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingList>>>
export type AppProductsProcessingListInfiniteQueryError = unknown

export const useAppProductsProcessingListInfinite = <TData = Awaited<ReturnType<typeof appProductsProcessingList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsProcessingListInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsProcessingListQueryOptions = <TData = Awaited<ReturnType<typeof appProductsProcessingList>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsProcessingListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsProcessingList>>> = ({ signal }) => appProductsProcessingList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsProcessingList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsProcessingListQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingList>>>
export type AppProductsProcessingListQueryError = unknown

export const useAppProductsProcessingList = <TData = Awaited<ReturnType<typeof appProductsProcessingList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsProcessingList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsProcessingListQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsProcessingCreate = (
    productUuid: string,
    productProcessingTypeCreateUpdate: ProductProcessingTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductProcessingTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/processing/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productProcessingTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsProcessingCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingCreate>>, TError,{productUuid: string;data: ProductProcessingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingCreate>>, TError,{productUuid: string;data: ProductProcessingTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsProcessingCreate>>, {productUuid: string;data: ProductProcessingTypeCreateUpdate}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  appProductsProcessingCreate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsProcessingCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingCreate>>>
    export type AppProductsProcessingCreateMutationBody = ProductProcessingTypeCreateUpdate
    export type AppProductsProcessingCreateMutationError = unknown

    export const useAppProductsProcessingCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingCreate>>, TError,{productUuid: string;data: ProductProcessingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsProcessingCreate>>,
        TError,
        {productUuid: string;data: ProductProcessingTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsProcessingCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsProcessingRetrieve = (
    productUuid: string,
    productProcessingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductProcessingTypeList>(
      {url: `/app/products/${productUuid}/processing/${productProcessingTypeUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsProcessingRetrieveQueryKey = (productUuid: string,
    productProcessingTypeUuid: string,) => {
    return [`/app/products/${productUuid}/processing/${productProcessingTypeUuid}/`] as const;
    }

    
export const getAppProductsProcessingRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError = unknown>(productUuid: string,
    productProcessingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsProcessingRetrieveQueryKey(productUuid,productProcessingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>> = ({ signal }) => appProductsProcessingRetrieve(productUuid,productProcessingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && productProcessingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsProcessingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>>
export type AppProductsProcessingRetrieveInfiniteQueryError = unknown

export const useAppProductsProcessingRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError = unknown>(
 productUuid: string,
    productProcessingTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsProcessingRetrieveInfiniteQueryOptions(productUuid,productProcessingTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsProcessingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError = unknown>(productUuid: string,
    productProcessingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsProcessingRetrieveQueryKey(productUuid,productProcessingTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>> = ({ signal }) => appProductsProcessingRetrieve(productUuid,productProcessingTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && productProcessingTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsProcessingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>>
export type AppProductsProcessingRetrieveQueryError = unknown

export const useAppProductsProcessingRetrieve = <TData = Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError = unknown>(
 productUuid: string,
    productProcessingTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsProcessingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsProcessingRetrieveQueryOptions(productUuid,productProcessingTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsProcessingUpdate = (
    productUuid: string,
    productProcessingTypeUuid: string,
    productProcessingTypeCreateUpdate: ProductProcessingTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductProcessingTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/processing/${productProcessingTypeUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productProcessingTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsProcessingUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingUpdate>>, TError,{productUuid: string;productProcessingTypeUuid: string;data: ProductProcessingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingUpdate>>, TError,{productUuid: string;productProcessingTypeUuid: string;data: ProductProcessingTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsProcessingUpdate>>, {productUuid: string;productProcessingTypeUuid: string;data: ProductProcessingTypeCreateUpdate}> = (props) => {
          const {productUuid,productProcessingTypeUuid,data} = props ?? {};

          return  appProductsProcessingUpdate(productUuid,productProcessingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsProcessingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingUpdate>>>
    export type AppProductsProcessingUpdateMutationBody = ProductProcessingTypeCreateUpdate
    export type AppProductsProcessingUpdateMutationError = unknown

    export const useAppProductsProcessingUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingUpdate>>, TError,{productUuid: string;productProcessingTypeUuid: string;data: ProductProcessingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsProcessingUpdate>>,
        TError,
        {productUuid: string;productProcessingTypeUuid: string;data: ProductProcessingTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsProcessingUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsProcessingPartialUpdate = (
    productUuid: string,
    productProcessingTypeUuid: string,
    patchedProductProcessingTypeCreateUpdate: PatchedProductProcessingTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductProcessingTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/processing/${productProcessingTypeUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedProductProcessingTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsProcessingPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingPartialUpdate>>, TError,{productUuid: string;productProcessingTypeUuid: string;data: PatchedProductProcessingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingPartialUpdate>>, TError,{productUuid: string;productProcessingTypeUuid: string;data: PatchedProductProcessingTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsProcessingPartialUpdate>>, {productUuid: string;productProcessingTypeUuid: string;data: PatchedProductProcessingTypeCreateUpdate}> = (props) => {
          const {productUuid,productProcessingTypeUuid,data} = props ?? {};

          return  appProductsProcessingPartialUpdate(productUuid,productProcessingTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsProcessingPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingPartialUpdate>>>
    export type AppProductsProcessingPartialUpdateMutationBody = PatchedProductProcessingTypeCreateUpdate
    export type AppProductsProcessingPartialUpdateMutationError = unknown

    export const useAppProductsProcessingPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingPartialUpdate>>, TError,{productUuid: string;productProcessingTypeUuid: string;data: PatchedProductProcessingTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsProcessingPartialUpdate>>,
        TError,
        {productUuid: string;productProcessingTypeUuid: string;data: PatchedProductProcessingTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsProcessingPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsProcessingDestroy = (
    productUuid: string,
    productProcessingTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/processing/${productProcessingTypeUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppProductsProcessingDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingDestroy>>, TError,{productUuid: string;productProcessingTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingDestroy>>, TError,{productUuid: string;productProcessingTypeUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsProcessingDestroy>>, {productUuid: string;productProcessingTypeUuid: string}> = (props) => {
          const {productUuid,productProcessingTypeUuid} = props ?? {};

          return  appProductsProcessingDestroy(productUuid,productProcessingTypeUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsProcessingDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsProcessingDestroy>>>
    
    export type AppProductsProcessingDestroyMutationError = unknown

    export const useAppProductsProcessingDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsProcessingDestroy>>, TError,{productUuid: string;productProcessingTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsProcessingDestroy>>,
        TError,
        {productUuid: string;productProcessingTypeUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsProcessingDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsResultRetrieve = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductResult>(
      {url: `/app/products/${productUuid}/result/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsResultRetrieveQueryKey = (productUuid: string,) => {
    return [`/app/products/${productUuid}/result/`] as const;
    }

    
export const getAppProductsResultRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsResultRetrieveQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsResultRetrieve>>> = ({ signal }) => appProductsResultRetrieve(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsResultRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsResultRetrieve>>>
export type AppProductsResultRetrieveInfiniteQueryError = unknown

export const useAppProductsResultRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsResultRetrieveInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsResultRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsResultRetrieveQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsResultRetrieve>>> = ({ signal }) => appProductsResultRetrieve(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsResultRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsResultRetrieve>>>
export type AppProductsResultRetrieveQueryError = unknown

export const useAppProductsResultRetrieve = <TData = Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsResultRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsResultRetrieveQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * View which accepts a post request to submit a product for assessment

All that happens at the moment is that the status is moved to In Progress
 */
export const appProductsSubmitCreate = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/submit/`, method: 'POST'
    },
      options);
    }
  


export const getAppProductsSubmitCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsSubmitCreate>>, TError,{productUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsSubmitCreate>>, TError,{productUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsSubmitCreate>>, {productUuid: string}> = (props) => {
          const {productUuid} = props ?? {};

          return  appProductsSubmitCreate(productUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsSubmitCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsSubmitCreate>>>
    
    export type AppProductsSubmitCreateMutationError = unknown

    export const useAppProductsSubmitCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsSubmitCreate>>, TError,{productUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsSubmitCreate>>,
        TError,
        {productUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsSubmitCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsTransportList = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductTransportTypeList[]>(
      {url: `/app/products/${productUuid}/transport/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsTransportListQueryKey = (productUuid: string,) => {
    return [`/app/products/${productUuid}/transport/`] as const;
    }

    
export const getAppProductsTransportListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsTransportList>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsTransportListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsTransportList>>> = ({ signal }) => appProductsTransportList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsTransportList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsTransportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportList>>>
export type AppProductsTransportListInfiniteQueryError = unknown

export const useAppProductsTransportListInfinite = <TData = Awaited<ReturnType<typeof appProductsTransportList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsTransportListInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsTransportListQueryOptions = <TData = Awaited<ReturnType<typeof appProductsTransportList>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsTransportListQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsTransportList>>> = ({ signal }) => appProductsTransportList(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsTransportList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportList>>>
export type AppProductsTransportListQueryError = unknown

export const useAppProductsTransportList = <TData = Awaited<ReturnType<typeof appProductsTransportList>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsTransportListQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsTransportCreate = (
    productUuid: string,
    productTransportTypeCreateUpdate: ProductTransportTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductTransportTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/transport/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productTransportTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsTransportCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportCreate>>, TError,{productUuid: string;data: ProductTransportTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportCreate>>, TError,{productUuid: string;data: ProductTransportTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsTransportCreate>>, {productUuid: string;data: ProductTransportTypeCreateUpdate}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  appProductsTransportCreate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsTransportCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportCreate>>>
    export type AppProductsTransportCreateMutationBody = ProductTransportTypeCreateUpdate
    export type AppProductsTransportCreateMutationError = unknown

    export const useAppProductsTransportCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportCreate>>, TError,{productUuid: string;data: ProductTransportTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsTransportCreate>>,
        TError,
        {productUuid: string;data: ProductTransportTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsTransportCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsTransportRetrieve = (
    productUuid: string,
    productTransportTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductTransportTypeList>(
      {url: `/app/products/${productUuid}/transport/${productTransportTypeUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsTransportRetrieveQueryKey = (productUuid: string,
    productTransportTypeUuid: string,) => {
    return [`/app/products/${productUuid}/transport/${productTransportTypeUuid}/`] as const;
    }

    
export const getAppProductsTransportRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError = unknown>(productUuid: string,
    productTransportTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsTransportRetrieveQueryKey(productUuid,productTransportTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsTransportRetrieve>>> = ({ signal }) => appProductsTransportRetrieve(productUuid,productTransportTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && productTransportTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsTransportRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportRetrieve>>>
export type AppProductsTransportRetrieveInfiniteQueryError = unknown

export const useAppProductsTransportRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError = unknown>(
 productUuid: string,
    productTransportTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsTransportRetrieveInfiniteQueryOptions(productUuid,productTransportTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsTransportRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError = unknown>(productUuid: string,
    productTransportTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsTransportRetrieveQueryKey(productUuid,productTransportTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsTransportRetrieve>>> = ({ signal }) => appProductsTransportRetrieve(productUuid,productTransportTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid && productTransportTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportRetrieve>>>
export type AppProductsTransportRetrieveQueryError = unknown

export const useAppProductsTransportRetrieve = <TData = Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError = unknown>(
 productUuid: string,
    productTransportTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsTransportRetrieveQueryOptions(productUuid,productTransportTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appProductsTransportUpdate = (
    productUuid: string,
    productTransportTypeUuid: string,
    productTransportTypeCreateUpdate: ProductTransportTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductTransportTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/transport/${productTransportTypeUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productTransportTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsTransportUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportUpdate>>, TError,{productUuid: string;productTransportTypeUuid: string;data: ProductTransportTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportUpdate>>, TError,{productUuid: string;productTransportTypeUuid: string;data: ProductTransportTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsTransportUpdate>>, {productUuid: string;productTransportTypeUuid: string;data: ProductTransportTypeCreateUpdate}> = (props) => {
          const {productUuid,productTransportTypeUuid,data} = props ?? {};

          return  appProductsTransportUpdate(productUuid,productTransportTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsTransportUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportUpdate>>>
    export type AppProductsTransportUpdateMutationBody = ProductTransportTypeCreateUpdate
    export type AppProductsTransportUpdateMutationError = unknown

    export const useAppProductsTransportUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportUpdate>>, TError,{productUuid: string;productTransportTypeUuid: string;data: ProductTransportTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsTransportUpdate>>,
        TError,
        {productUuid: string;productTransportTypeUuid: string;data: ProductTransportTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsTransportUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsTransportPartialUpdate = (
    productUuid: string,
    productTransportTypeUuid: string,
    patchedProductTransportTypeCreateUpdate: PatchedProductTransportTypeCreateUpdate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductTransportTypeCreateUpdate>(
      {url: `/app/products/${productUuid}/transport/${productTransportTypeUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedProductTransportTypeCreateUpdate
    },
      options);
    }
  


export const getAppProductsTransportPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportPartialUpdate>>, TError,{productUuid: string;productTransportTypeUuid: string;data: PatchedProductTransportTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportPartialUpdate>>, TError,{productUuid: string;productTransportTypeUuid: string;data: PatchedProductTransportTypeCreateUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsTransportPartialUpdate>>, {productUuid: string;productTransportTypeUuid: string;data: PatchedProductTransportTypeCreateUpdate}> = (props) => {
          const {productUuid,productTransportTypeUuid,data} = props ?? {};

          return  appProductsTransportPartialUpdate(productUuid,productTransportTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsTransportPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportPartialUpdate>>>
    export type AppProductsTransportPartialUpdateMutationBody = PatchedProductTransportTypeCreateUpdate
    export type AppProductsTransportPartialUpdateMutationError = unknown

    export const useAppProductsTransportPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportPartialUpdate>>, TError,{productUuid: string;productTransportTypeUuid: string;data: PatchedProductTransportTypeCreateUpdate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsTransportPartialUpdate>>,
        TError,
        {productUuid: string;productTransportTypeUuid: string;data: PatchedProductTransportTypeCreateUpdate},
        TContext
      > => {

      const mutationOptions = getAppProductsTransportPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsTransportDestroy = (
    productUuid: string,
    productTransportTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/products/${productUuid}/transport/${productTransportTypeUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppProductsTransportDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportDestroy>>, TError,{productUuid: string;productTransportTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportDestroy>>, TError,{productUuid: string;productTransportTypeUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsTransportDestroy>>, {productUuid: string;productTransportTypeUuid: string}> = (props) => {
          const {productUuid,productTransportTypeUuid} = props ?? {};

          return  appProductsTransportDestroy(productUuid,productTransportTypeUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsTransportDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsTransportDestroy>>>
    
    export type AppProductsTransportDestroyMutationError = unknown

    export const useAppProductsTransportDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsTransportDestroy>>, TError,{productUuid: string;productTransportTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsTransportDestroy>>,
        TError,
        {productUuid: string;productTransportTypeUuid: string},
        TContext
      > => {

      const mutationOptions = getAppProductsTransportDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsAssessCreate = (
    assessRequest: AssessRequest,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<AssessResponse>(
      {url: `/app/products/assess`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assessRequest
    },
      options);
    }
  


export const getAppProductsAssessCreateMutationOptions = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsAssessCreate>>, TError,{data: AssessRequest}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appProductsAssessCreate>>, TError,{data: AssessRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appProductsAssessCreate>>, {data: AssessRequest}> = (props) => {
          const {data} = props ?? {};

          return  appProductsAssessCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppProductsAssessCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appProductsAssessCreate>>>
    export type AppProductsAssessCreateMutationBody = AssessRequest
    export type AppProductsAssessCreateMutationError = void

    export const useAppProductsAssessCreate = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appProductsAssessCreate>>, TError,{data: AssessRequest}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appProductsAssessCreate>>,
        TError,
        {data: AssessRequest},
        TContext
      > => {

      const mutationOptions = getAppProductsAssessCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appProductsCsvRetrieve = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<void>(
      {url: `/app/products/csv/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppProductsCsvRetrieveQueryKey = () => {
    return [`/app/products/csv/`] as const;
    }

    
export const getAppProductsCsvRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsCsvRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsCsvRetrieve>>> = ({ signal }) => appProductsCsvRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsCsvRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsCsvRetrieve>>>
export type AppProductsCsvRetrieveInfiniteQueryError = unknown

export const useAppProductsCsvRetrieveInfinite = <TData = Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsCsvRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppProductsCsvRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppProductsCsvRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appProductsCsvRetrieve>>> = ({ signal }) => appProductsCsvRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppProductsCsvRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appProductsCsvRetrieve>>>
export type AppProductsCsvRetrieveQueryError = unknown

export const useAppProductsCsvRetrieve = <TData = Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appProductsCsvRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppProductsCsvRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appReportsList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Report[]>(
      {url: `/app/reports/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppReportsListQueryKey = () => {
    return [`/app/reports/`] as const;
    }

    
export const getAppReportsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appReportsList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsList>>> = ({ signal }) => appReportsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsList>>>
export type AppReportsListInfiniteQueryError = unknown

export const useAppReportsListInfinite = <TData = Awaited<ReturnType<typeof appReportsList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppReportsListQueryOptions = <TData = Awaited<ReturnType<typeof appReportsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsList>>> = ({ signal }) => appReportsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appReportsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsList>>>
export type AppReportsListQueryError = unknown

export const useAppReportsList = <TData = Awaited<ReturnType<typeof appReportsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appReportsProductionRetrieve = (
    reportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ReportProductDetail>(
      {url: `/app/reports/production/${reportUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppReportsProductionRetrieveQueryKey = (reportUuid: string,) => {
    return [`/app/reports/production/${reportUuid}/`] as const;
    }

    
export const getAppReportsProductionRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError = unknown>(reportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsProductionRetrieveQueryKey(reportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsProductionRetrieve>>> = ({ signal }) => appReportsProductionRetrieve(reportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsProductionRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsProductionRetrieve>>>
export type AppReportsProductionRetrieveInfiniteQueryError = unknown

export const useAppReportsProductionRetrieveInfinite = <TData = Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError = unknown>(
 reportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsProductionRetrieveInfiniteQueryOptions(reportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppReportsProductionRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError = unknown>(reportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsProductionRetrieveQueryKey(reportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsProductionRetrieve>>> = ({ signal }) => appReportsProductionRetrieve(reportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsProductionRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsProductionRetrieve>>>
export type AppReportsProductionRetrieveQueryError = unknown

export const useAppReportsProductionRetrieve = <TData = Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError = unknown>(
 reportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsProductionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsProductionRetrieveQueryOptions(reportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appReportsPurchasingRetrieve = (
    reportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ReportOrganizationFoodDetail>(
      {url: `/app/reports/purchasing/${reportUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppReportsPurchasingRetrieveQueryKey = (reportUuid: string,) => {
    return [`/app/reports/purchasing/${reportUuid}/`] as const;
    }

    
export const getAppReportsPurchasingRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError = unknown>(reportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsPurchasingRetrieveQueryKey(reportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>> = ({ signal }) => appReportsPurchasingRetrieve(reportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsPurchasingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>>
export type AppReportsPurchasingRetrieveInfiniteQueryError = unknown

export const useAppReportsPurchasingRetrieveInfinite = <TData = Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError = unknown>(
 reportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsPurchasingRetrieveInfiniteQueryOptions(reportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppReportsPurchasingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError = unknown>(reportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsPurchasingRetrieveQueryKey(reportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>> = ({ signal }) => appReportsPurchasingRetrieve(reportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsPurchasingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>>
export type AppReportsPurchasingRetrieveQueryError = unknown

export const useAppReportsPurchasingRetrieve = <TData = Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError = unknown>(
 reportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsPurchasingRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsPurchasingRetrieveQueryOptions(reportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appReportsSalesRetrieve = (
    reportUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ReportProductDetail>(
      {url: `/app/reports/sales/${reportUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppReportsSalesRetrieveQueryKey = (reportUuid: string,) => {
    return [`/app/reports/sales/${reportUuid}/`] as const;
    }

    
export const getAppReportsSalesRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError = unknown>(reportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsSalesRetrieveQueryKey(reportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsSalesRetrieve>>> = ({ signal }) => appReportsSalesRetrieve(reportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsSalesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsSalesRetrieve>>>
export type AppReportsSalesRetrieveInfiniteQueryError = unknown

export const useAppReportsSalesRetrieveInfinite = <TData = Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError = unknown>(
 reportUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsSalesRetrieveInfiniteQueryOptions(reportUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppReportsSalesRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError = unknown>(reportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppReportsSalesRetrieveQueryKey(reportUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appReportsSalesRetrieve>>> = ({ signal }) => appReportsSalesRetrieve(reportUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(reportUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppReportsSalesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appReportsSalesRetrieve>>>
export type AppReportsSalesRetrieveQueryError = unknown

export const useAppReportsSalesRetrieve = <TData = Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError = unknown>(
 reportUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appReportsSalesRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppReportsSalesRetrieveQueryOptions(reportUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a shared link for an entity
 */
export const appShareCreate = (
    sharedLinkResponse: NonReadonly<SharedLinkResponse>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<SharedLinkResponse>(
      {url: `/app/share/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sharedLinkResponse
    },
      options);
    }
  


export const getAppShareCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appShareCreate>>, TError,{data: NonReadonly<SharedLinkResponse>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appShareCreate>>, TError,{data: NonReadonly<SharedLinkResponse>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appShareCreate>>, {data: NonReadonly<SharedLinkResponse>}> = (props) => {
          const {data} = props ?? {};

          return  appShareCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppShareCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appShareCreate>>>
    export type AppShareCreateMutationBody = NonReadonly<SharedLinkResponse>
    export type AppShareCreateMutationError = unknown

    export const useAppShareCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appShareCreate>>, TError,{data: NonReadonly<SharedLinkResponse>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appShareCreate>>,
        TError,
        {data: NonReadonly<SharedLinkResponse>},
        TContext
      > => {

      const mutationOptions = getAppShareCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Verify a shared link by its UUID (GET) or delete the shared link (DELETE).
 */
export const appShareRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<SharedLinkVerifyResponse>(
      {url: `/app/share/${uuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppShareRetrieveQueryKey = (uuid: string,) => {
    return [`/app/share/${uuid}/`] as const;
    }

    
export const getAppShareRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareRetrieve>>> = ({ signal }) => appShareRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareRetrieve>>>
export type AppShareRetrieveInfiniteQueryError = unknown

export const useAppShareRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareRetrieve>>> = ({ signal }) => appShareRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareRetrieve>>>
export type AppShareRetrieveQueryError = unknown

export const useAppShareRetrieve = <TData = Awaited<ReturnType<typeof appShareRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Verify a shared link by its UUID (GET) or delete the shared link (DELETE).
 */
export const appShareDestroy = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/share/${uuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppShareDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appShareDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appShareDestroy>>, TError,{uuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appShareDestroy>>, {uuid: string}> = (props) => {
          const {uuid} = props ?? {};

          return  appShareDestroy(uuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppShareDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appShareDestroy>>>
    
    export type AppShareDestroyMutationError = unknown

    export const useAppShareDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appShareDestroy>>, TError,{uuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appShareDestroy>>,
        TError,
        {uuid: string},
        TContext
      > => {

      const mutationOptions = getAppShareDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appShareResultCollectionRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<CollectionDetail>(
      {url: `/app/share/${uuid}/result/collection`, method: 'GET', signal
    },
      options);
    }
  

export const getAppShareResultCollectionRetrieveQueryKey = (uuid: string,) => {
    return [`/app/share/${uuid}/result/collection`] as const;
    }

    
export const getAppShareResultCollectionRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultCollectionRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>> = ({ signal }) => appShareResultCollectionRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultCollectionRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>>
export type AppShareResultCollectionRetrieveInfiniteQueryError = unknown

export const useAppShareResultCollectionRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultCollectionRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareResultCollectionRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultCollectionRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>> = ({ signal }) => appShareResultCollectionRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultCollectionRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>>
export type AppShareResultCollectionRetrieveQueryError = unknown

export const useAppShareResultCollectionRetrieve = <TData = Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultCollectionRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultCollectionRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appShareResultProductAssessmentRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductResult>(
      {url: `/app/share/${uuid}/result/product-assessment`, method: 'GET', signal
    },
      options);
    }
  

export const getAppShareResultProductAssessmentRetrieveQueryKey = (uuid: string,) => {
    return [`/app/share/${uuid}/result/product-assessment`] as const;
    }

    
export const getAppShareResultProductAssessmentRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultProductAssessmentRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>> = ({ signal }) => appShareResultProductAssessmentRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultProductAssessmentRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>>
export type AppShareResultProductAssessmentRetrieveInfiniteQueryError = unknown

export const useAppShareResultProductAssessmentRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultProductAssessmentRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareResultProductAssessmentRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultProductAssessmentRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>> = ({ signal }) => appShareResultProductAssessmentRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultProductAssessmentRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>>
export type AppShareResultProductAssessmentRetrieveQueryError = unknown

export const useAppShareResultProductAssessmentRetrieve = <TData = Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultProductAssessmentRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultProductAssessmentRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appShareResultProductionReportRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ReportProductDetail>(
      {url: `/app/share/${uuid}/result/production-report`, method: 'GET', signal
    },
      options);
    }
  

export const getAppShareResultProductionReportRetrieveQueryKey = (uuid: string,) => {
    return [`/app/share/${uuid}/result/production-report`] as const;
    }

    
export const getAppShareResultProductionReportRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultProductionReportRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>> = ({ signal }) => appShareResultProductionReportRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultProductionReportRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>>
export type AppShareResultProductionReportRetrieveInfiniteQueryError = unknown

export const useAppShareResultProductionReportRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultProductionReportRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareResultProductionReportRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultProductionReportRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>> = ({ signal }) => appShareResultProductionReportRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultProductionReportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>>
export type AppShareResultProductionReportRetrieveQueryError = unknown

export const useAppShareResultProductionReportRetrieve = <TData = Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultProductionReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultProductionReportRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appShareResultPurchasingReportRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ReportOrganizationFoodDetail>(
      {url: `/app/share/${uuid}/result/purchasing-report`, method: 'GET', signal
    },
      options);
    }
  

export const getAppShareResultPurchasingReportRetrieveQueryKey = (uuid: string,) => {
    return [`/app/share/${uuid}/result/purchasing-report`] as const;
    }

    
export const getAppShareResultPurchasingReportRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultPurchasingReportRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>> = ({ signal }) => appShareResultPurchasingReportRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultPurchasingReportRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>>
export type AppShareResultPurchasingReportRetrieveInfiniteQueryError = unknown

export const useAppShareResultPurchasingReportRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultPurchasingReportRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareResultPurchasingReportRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultPurchasingReportRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>> = ({ signal }) => appShareResultPurchasingReportRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultPurchasingReportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>>
export type AppShareResultPurchasingReportRetrieveQueryError = unknown

export const useAppShareResultPurchasingReportRetrieve = <TData = Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultPurchasingReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultPurchasingReportRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appShareResultSalesReportRetrieve = (
    uuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ReportProductDetail>(
      {url: `/app/share/${uuid}/result/sales-report`, method: 'GET', signal
    },
      options);
    }
  

export const getAppShareResultSalesReportRetrieveQueryKey = (uuid: string,) => {
    return [`/app/share/${uuid}/result/sales-report`] as const;
    }

    
export const getAppShareResultSalesReportRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultSalesReportRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>> = ({ signal }) => appShareResultSalesReportRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultSalesReportRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>>
export type AppShareResultSalesReportRetrieveInfiniteQueryError = unknown

export const useAppShareResultSalesReportRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultSalesReportRetrieveInfiniteQueryOptions(uuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareResultSalesReportRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError = unknown>(uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareResultSalesReportRetrieveQueryKey(uuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>> = ({ signal }) => appShareResultSalesReportRetrieve(uuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(uuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareResultSalesReportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>>
export type AppShareResultSalesReportRetrieveQueryError = unknown

export const useAppShareResultSalesReportRetrieve = <TData = Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError = unknown>(
 uuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareResultSalesReportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareResultSalesReportRetrieveQueryOptions(uuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appShareQueryRetrieve = (
    params?: AppShareQueryRetrieveParams,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<SharedLinkResponse>(
      {url: `/app/share/query/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAppShareQueryRetrieveQueryKey = (params?: AppShareQueryRetrieveParams,) => {
    return [`/app/share/query/`, ...(params ? [params]: [])] as const;
    }

    
export const getAppShareQueryRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError = unknown>(params?: AppShareQueryRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareQueryRetrieveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareQueryRetrieve>>> = ({ signal, pageParam }) => appShareQueryRetrieve({...params, page: pageParam || params?.['page']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareQueryRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appShareQueryRetrieve>>>
export type AppShareQueryRetrieveInfiniteQueryError = unknown

export const useAppShareQueryRetrieveInfinite = <TData = Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError = unknown>(
 params?: AppShareQueryRetrieveParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareQueryRetrieveInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppShareQueryRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError = unknown>(params?: AppShareQueryRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppShareQueryRetrieveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appShareQueryRetrieve>>> = ({ signal }) => appShareQueryRetrieve(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppShareQueryRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appShareQueryRetrieve>>>
export type AppShareQueryRetrieveQueryError = unknown

export const useAppShareQueryRetrieve = <TData = Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError = unknown>(
 params?: AppShareQueryRetrieveParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appShareQueryRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppShareQueryRetrieveQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appTargetsList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<TargetList[]>(
      {url: `/app/targets/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppTargetsListQueryKey = () => {
    return [`/app/targets/`] as const;
    }

    
export const getAppTargetsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appTargetsList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTargetsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTargetsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTargetsList>>> = ({ signal }) => appTargetsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTargetsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTargetsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appTargetsList>>>
export type AppTargetsListInfiniteQueryError = unknown

export const useAppTargetsListInfinite = <TData = Awaited<ReturnType<typeof appTargetsList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTargetsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTargetsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppTargetsListQueryOptions = <TData = Awaited<ReturnType<typeof appTargetsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTargetsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTargetsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTargetsList>>> = ({ signal }) => appTargetsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appTargetsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTargetsListQueryResult = NonNullable<Awaited<ReturnType<typeof appTargetsList>>>
export type AppTargetsListQueryError = unknown

export const useAppTargetsList = <TData = Awaited<ReturnType<typeof appTargetsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTargetsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTargetsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appTargetsCreate = (
    targetCreate: TargetCreate,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<TargetCreate>(
      {url: `/app/targets/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: targetCreate
    },
      options);
    }
  


export const getAppTargetsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTargetsCreate>>, TError,{data: TargetCreate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTargetsCreate>>, TError,{data: TargetCreate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTargetsCreate>>, {data: TargetCreate}> = (props) => {
          const {data} = props ?? {};

          return  appTargetsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTargetsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appTargetsCreate>>>
    export type AppTargetsCreateMutationBody = TargetCreate
    export type AppTargetsCreateMutationError = unknown

    export const useAppTargetsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTargetsCreate>>, TError,{data: TargetCreate}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTargetsCreate>>,
        TError,
        {data: TargetCreate},
        TContext
      > => {

      const mutationOptions = getAppTargetsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTargetsDestroy = (
    targetUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/targets/${targetUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppTargetsDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTargetsDestroy>>, TError,{targetUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTargetsDestroy>>, TError,{targetUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTargetsDestroy>>, {targetUuid: string}> = (props) => {
          const {targetUuid} = props ?? {};

          return  appTargetsDestroy(targetUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTargetsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appTargetsDestroy>>>
    
    export type AppTargetsDestroyMutationError = unknown

    export const useAppTargetsDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTargetsDestroy>>, TError,{targetUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTargetsDestroy>>,
        TError,
        {targetUuid: string},
        TContext
      > => {

      const mutationOptions = getAppTargetsDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTargetsMetricsList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<MetricsList[]>(
      {url: `/app/targets/metrics/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppTargetsMetricsListQueryKey = () => {
    return [`/app/targets/metrics/`] as const;
    }

    
export const getAppTargetsMetricsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appTargetsMetricsList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTargetsMetricsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTargetsMetricsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTargetsMetricsList>>> = ({ signal }) => appTargetsMetricsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTargetsMetricsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTargetsMetricsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appTargetsMetricsList>>>
export type AppTargetsMetricsListInfiniteQueryError = unknown

export const useAppTargetsMetricsListInfinite = <TData = Awaited<ReturnType<typeof appTargetsMetricsList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTargetsMetricsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTargetsMetricsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppTargetsMetricsListQueryOptions = <TData = Awaited<ReturnType<typeof appTargetsMetricsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTargetsMetricsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTargetsMetricsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTargetsMetricsList>>> = ({ signal }) => appTargetsMetricsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appTargetsMetricsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTargetsMetricsListQueryResult = NonNullable<Awaited<ReturnType<typeof appTargetsMetricsList>>>
export type AppTargetsMetricsListQueryError = unknown

export const useAppTargetsMetricsList = <TData = Awaited<ReturnType<typeof appTargetsMetricsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTargetsMetricsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTargetsMetricsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const appTokenRefresh2Create = (
    tokenRefresh: NonReadonly<TokenRefresh>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/token/refresh2/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: tokenRefresh
    },
      options);
    }
  


export const getAppTokenRefresh2CreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTokenRefresh2Create>>, TError,{data: NonReadonly<TokenRefresh>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTokenRefresh2Create>>, TError,{data: NonReadonly<TokenRefresh>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTokenRefresh2Create>>, {data: NonReadonly<TokenRefresh>}> = (props) => {
          const {data} = props ?? {};

          return  appTokenRefresh2Create(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTokenRefresh2CreateMutationResult = NonNullable<Awaited<ReturnType<typeof appTokenRefresh2Create>>>
    export type AppTokenRefresh2CreateMutationBody = NonReadonly<TokenRefresh>
    export type AppTokenRefresh2CreateMutationError = unknown

    export const useAppTokenRefresh2Create = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTokenRefresh2Create>>, TError,{data: NonReadonly<TokenRefresh>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTokenRefresh2Create>>,
        TError,
        {data: NonReadonly<TokenRefresh>},
        TContext
      > => {

      const mutationOptions = getAppTokenRefresh2CreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Takes a token and indicates if it is valid.  This view provides no
information about a token's fitness for a particular use.
 */
export const appTokenVerifyCreate = (
    tokenVerify: TokenVerify,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<unknown>(
      {url: `/app/token/verify/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: tokenVerify
    },
      options);
    }
  


export const getAppTokenVerifyCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTokenVerifyCreate>>, TError,{data: TokenVerify}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTokenVerifyCreate>>, TError,{data: TokenVerify}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTokenVerifyCreate>>, {data: TokenVerify}> = (props) => {
          const {data} = props ?? {};

          return  appTokenVerifyCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTokenVerifyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appTokenVerifyCreate>>>
    export type AppTokenVerifyCreateMutationBody = TokenVerify
    export type AppTokenVerifyCreateMutationError = unknown

    export const useAppTokenVerifyCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTokenVerifyCreate>>, TError,{data: TokenVerify}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTokenVerifyCreate>>,
        TError,
        {data: TokenVerify},
        TContext
      > => {

      const mutationOptions = getAppTokenVerifyCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTransportList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<JourneyGroupList[]>(
      {url: `/app/transport/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppTransportListQueryKey = () => {
    return [`/app/transport/`] as const;
    }

    
export const getAppTransportListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appTransportList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTransportListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTransportList>>> = ({ signal }) => appTransportList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTransportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appTransportList>>>
export type AppTransportListInfiniteQueryError = unknown

export const useAppTransportListInfinite = <TData = Awaited<ReturnType<typeof appTransportList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTransportListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppTransportListQueryOptions = <TData = Awaited<ReturnType<typeof appTransportList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTransportListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTransportList>>> = ({ signal }) => appTransportList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appTransportList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof appTransportList>>>
export type AppTransportListQueryError = unknown

export const useAppTransportList = <TData = Awaited<ReturnType<typeof appTransportList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTransportList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTransportListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appTransportCreate = (
    journeyGroupCreate: NonReadonly<JourneyGroupCreate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<JourneyGroupCreate>(
      {url: `/app/transport/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: journeyGroupCreate
    },
      options);
    }
  


export const getAppTransportCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportCreate>>, TError,{data: NonReadonly<JourneyGroupCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTransportCreate>>, TError,{data: NonReadonly<JourneyGroupCreate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTransportCreate>>, {data: NonReadonly<JourneyGroupCreate>}> = (props) => {
          const {data} = props ?? {};

          return  appTransportCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTransportCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appTransportCreate>>>
    export type AppTransportCreateMutationBody = NonReadonly<JourneyGroupCreate>
    export type AppTransportCreateMutationError = unknown

    export const useAppTransportCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportCreate>>, TError,{data: NonReadonly<JourneyGroupCreate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTransportCreate>>,
        TError,
        {data: NonReadonly<JourneyGroupCreate>},
        TContext
      > => {

      const mutationOptions = getAppTransportCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTransportRetrieve = (
    transportTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<JourneyGroupList>(
      {url: `/app/transport/${transportTypeUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppTransportRetrieveQueryKey = (transportTypeUuid: string,) => {
    return [`/app/transport/${transportTypeUuid}/`] as const;
    }

    
export const getAppTransportRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appTransportRetrieve>>, TError = unknown>(transportTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTransportRetrieveQueryKey(transportTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTransportRetrieve>>> = ({ signal }) => appTransportRetrieve(transportTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(transportTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTransportRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appTransportRetrieve>>>
export type AppTransportRetrieveInfiniteQueryError = unknown

export const useAppTransportRetrieveInfinite = <TData = Awaited<ReturnType<typeof appTransportRetrieve>>, TError = unknown>(
 transportTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTransportRetrieveInfiniteQueryOptions(transportTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppTransportRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appTransportRetrieve>>, TError = unknown>(transportTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTransportRetrieveQueryKey(transportTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTransportRetrieve>>> = ({ signal }) => appTransportRetrieve(transportTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(transportTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appTransportRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appTransportRetrieve>>>
export type AppTransportRetrieveQueryError = unknown

export const useAppTransportRetrieve = <TData = Awaited<ReturnType<typeof appTransportRetrieve>>, TError = unknown>(
 transportTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTransportRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTransportRetrieveQueryOptions(transportTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appTransportUpdate = (
    transportTypeUuid: string,
    journeyGroupUpdate: NonReadonly<JourneyGroupUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<JourneyGroupUpdate>(
      {url: `/app/transport/${transportTypeUuid}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: journeyGroupUpdate
    },
      options);
    }
  


export const getAppTransportUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportUpdate>>, TError,{transportTypeUuid: string;data: NonReadonly<JourneyGroupUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTransportUpdate>>, TError,{transportTypeUuid: string;data: NonReadonly<JourneyGroupUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTransportUpdate>>, {transportTypeUuid: string;data: NonReadonly<JourneyGroupUpdate>}> = (props) => {
          const {transportTypeUuid,data} = props ?? {};

          return  appTransportUpdate(transportTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTransportUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appTransportUpdate>>>
    export type AppTransportUpdateMutationBody = NonReadonly<JourneyGroupUpdate>
    export type AppTransportUpdateMutationError = unknown

    export const useAppTransportUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportUpdate>>, TError,{transportTypeUuid: string;data: NonReadonly<JourneyGroupUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTransportUpdate>>,
        TError,
        {transportTypeUuid: string;data: NonReadonly<JourneyGroupUpdate>},
        TContext
      > => {

      const mutationOptions = getAppTransportUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTransportPartialUpdate = (
    transportTypeUuid: string,
    patchedJourneyGroupUpdate: NonReadonly<PatchedJourneyGroupUpdate>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<JourneyGroupUpdate>(
      {url: `/app/transport/${transportTypeUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedJourneyGroupUpdate
    },
      options);
    }
  


export const getAppTransportPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportPartialUpdate>>, TError,{transportTypeUuid: string;data: NonReadonly<PatchedJourneyGroupUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTransportPartialUpdate>>, TError,{transportTypeUuid: string;data: NonReadonly<PatchedJourneyGroupUpdate>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTransportPartialUpdate>>, {transportTypeUuid: string;data: NonReadonly<PatchedJourneyGroupUpdate>}> = (props) => {
          const {transportTypeUuid,data} = props ?? {};

          return  appTransportPartialUpdate(transportTypeUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTransportPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appTransportPartialUpdate>>>
    export type AppTransportPartialUpdateMutationBody = NonReadonly<PatchedJourneyGroupUpdate>
    export type AppTransportPartialUpdateMutationError = unknown

    export const useAppTransportPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportPartialUpdate>>, TError,{transportTypeUuid: string;data: NonReadonly<PatchedJourneyGroupUpdate>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTransportPartialUpdate>>,
        TError,
        {transportTypeUuid: string;data: NonReadonly<PatchedJourneyGroupUpdate>},
        TContext
      > => {

      const mutationOptions = getAppTransportPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTransportDestroy = (
    transportTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<void>(
      {url: `/app/transport/${transportTypeUuid}/`, method: 'DELETE'
    },
      options);
    }
  


export const getAppTransportDestroyMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportDestroy>>, TError,{transportTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTransportDestroy>>, TError,{transportTypeUuid: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTransportDestroy>>, {transportTypeUuid: string}> = (props) => {
          const {transportTypeUuid} = props ?? {};

          return  appTransportDestroy(transportTypeUuid,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTransportDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof appTransportDestroy>>>
    
    export type AppTransportDestroyMutationError = unknown

    export const useAppTransportDestroy = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportDestroy>>, TError,{transportTypeUuid: string}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTransportDestroy>>,
        TError,
        {transportTypeUuid: string},
        TContext
      > => {

      const mutationOptions = getAppTransportDestroyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appTransportFileRetrieve = (
    transportTypeUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<TransportTypeFileRetrieve>(
      {url: `/app/transport/${transportTypeUuid}/file/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppTransportFileRetrieveQueryKey = (transportTypeUuid: string,) => {
    return [`/app/transport/${transportTypeUuid}/file/`] as const;
    }

    
export const getAppTransportFileRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError = unknown>(transportTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTransportFileRetrieveQueryKey(transportTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTransportFileRetrieve>>> = ({ signal }) => appTransportFileRetrieve(transportTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(transportTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTransportFileRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appTransportFileRetrieve>>>
export type AppTransportFileRetrieveInfiniteQueryError = unknown

export const useAppTransportFileRetrieveInfinite = <TData = Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError = unknown>(
 transportTypeUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTransportFileRetrieveInfiniteQueryOptions(transportTypeUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppTransportFileRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError = unknown>(transportTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppTransportFileRetrieveQueryKey(transportTypeUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appTransportFileRetrieve>>> = ({ signal }) => appTransportFileRetrieve(transportTypeUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(transportTypeUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type AppTransportFileRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof appTransportFileRetrieve>>>
export type AppTransportFileRetrieveQueryError = unknown

export const useAppTransportFileRetrieve = <TData = Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError = unknown>(
 transportTypeUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appTransportFileRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppTransportFileRetrieveQueryOptions(transportTypeUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const appTransportFileCreate = (
    transportTypeUuid: string,
    transportTypeFileUuid: string,
    transportTypeFileFinish: TransportTypeFileFinish,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<TransportTypeFileFinish>(
      {url: `/app/transport/${transportTypeUuid}/file/${transportTypeFileUuid}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: transportTypeFileFinish
    },
      options);
    }
  


export const getAppTransportFileCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportFileCreate>>, TError,{transportTypeUuid: string;transportTypeFileUuid: string;data: TransportTypeFileFinish}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof appTransportFileCreate>>, TError,{transportTypeUuid: string;transportTypeFileUuid: string;data: TransportTypeFileFinish}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof appTransportFileCreate>>, {transportTypeUuid: string;transportTypeFileUuid: string;data: TransportTypeFileFinish}> = (props) => {
          const {transportTypeUuid,transportTypeFileUuid,data} = props ?? {};

          return  appTransportFileCreate(transportTypeUuid,transportTypeFileUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AppTransportFileCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appTransportFileCreate>>>
    export type AppTransportFileCreateMutationBody = TransportTypeFileFinish
    export type AppTransportFileCreateMutationError = unknown

    export const useAppTransportFileCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof appTransportFileCreate>>, TError,{transportTypeUuid: string;transportTypeFileUuid: string;data: TransportTypeFileFinish}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof appTransportFileCreate>>,
        TError,
        {transportTypeUuid: string;transportTypeFileUuid: string;data: TransportTypeFileFinish},
        TContext
      > => {

      const mutationOptions = getAppTransportFileCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const appUnitsList = (
    
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<Unit[]>(
      {url: `/app/units/`, method: 'GET', signal
    },
      options);
    }
  

export const getAppUnitsListQueryKey = () => {
    return [`/app/units/`] as const;
    }

    
export const getAppUnitsListInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof appUnitsList>>, TError = unknown>( options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppUnitsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appUnitsList>>> = ({ signal }) => appUnitsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof appUnitsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppUnitsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appUnitsList>>>
export type AppUnitsListInfiniteQueryError = unknown

export const useAppUnitsListInfinite = <TData = Awaited<ReturnType<typeof appUnitsList>>, TError = unknown>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof appUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppUnitsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAppUnitsListQueryOptions = <TData = Awaited<ReturnType<typeof appUnitsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAppUnitsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appUnitsList>>> = ({ signal }) => appUnitsList(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof appUnitsList>>, TError, TData> & { queryKey: QueryKey }
}

export type AppUnitsListQueryResult = NonNullable<Awaited<ReturnType<typeof appUnitsList>>>
export type AppUnitsListQueryError = unknown

export const useAppUnitsList = <TData = Awaited<ReturnType<typeof appUnitsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof appUnitsList>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAppUnitsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



